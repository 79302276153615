import React from 'react'
import Background from '../componants/Background';

export default function Medications() {
  return (
    <div className="contentC">
      <Background />
    </div>
  );
}
