import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const auth = getAuth();

  function handleSubmit(event) {
    event.preventDefault();

    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      switch (error.code) {
        case "auth/invalid-email":
          setErrorMessage("Please enter a correct email address");
          break;
        case "auth/wrong-password":
          setErrorMessage("The password that you have entered is incorrect");
          break;
        case "auth/user-not-found":
          setErrorMessage("No account was found linked to this email address");
          break;
        case "auth/internal-error":
          setErrorMessage("The password must be at leat 6 characters long");
          break;
      }
    });
  }

  return (
    <div className="formCenter">
      <form className="formFields" onSubmit={handleSubmit}>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="email">
            E-Mail Address
          </label>
          <input
            type="email"
            id="email"
            className="formFieldInput"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(txt) => setEmail(txt.target.value)}
          />
        </div>

        <div className="formField">
          <label className="formFieldLabel" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="formFieldInput"
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(txt) => setPassword(txt.target.value)}
          />
        </div>

        {errorMessage && (
          <div>
            <h3 style={{ color: "red", fontWeight: 500, marginBottom: 7 }}>{errorMessage}</h3>
          </div>
        )}

        <div className="formField">
          <button className="formFieldButton" onClick={handleSubmit}>
            Sign In
          </button>

          <Link to="/" className="formFieldLink" style={{ marginLeft: 10 }}>
            Create an account
          </Link>

          <Link to="/forgot-password" className="formFieldLink" style={{ marginLeft: 10 }}>
            Forgot Password
          </Link>
        </div>
      </form>
    </div>
  );
}

export default SignInForm;
