import React, { useEffect, useState } from "react";
import Background from "../componants/Background";
import { deleteUser, getAuth, signOut } from "firebase/auth";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import Modal from "react-modal";
import { Link } from "react-router-dom";

export default function Profile() {
  const [info, setInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    async function getPhData() {
      const phRef = doc(db, "pharmacies", auth.currentUser.email);
      const docsSnap = await getDoc(phRef);
      setInfo(docsSnap.data());
    }

    getPhData();
  }, []);

  const logOut = () => {
    signOut(auth);
  };

  const deleteAccount = () => {
    deleteUser(auth.currentUser).catch((er) => {
      alert("Please log out and in again before deleting your account");
    });
  };

  return (
    <div className="contentC">
      <div className="contentMC">
        <img
          src={require("../docAvatar.png")}
          style={{
            width: 100,
            height: 100,
            resizeMode: "cover",
            borderRadius: 50,
            marginTop: -59,
            border: "solid",
            borderWidth: 2,
            borderColor: "#2872fa",
          }}
        />
        <h3 style={{ marginTop: 10 }}>{auth.currentUser.displayName}</h3>
        <div>
          <div style={{ color: "#444", paddingLeft: 10, marginTop: 10 }}>
            <div className="patientDetails">
              <h4>Email Address:&nbsp;&nbsp;</h4>
              <p>{info.email}</p>
            </div>
            <div className="patientDetails">
              <h4>Phone Number:&nbsp;&nbsp;</h4>
              <p>{info.phone}</p>
            </div>
            <div className="patientDetails">
              <h4>Fax:&nbsp;&nbsp;</h4>
              <p>{info.fax}</p>
            </div>
            <div className="patientDetails">
              <h4>Postal Code:&nbsp;&nbsp;</h4>
              <p>{"K2D3F2"}</p>
            </div>
            <div className={"patientDetails addressD"}>
              <h4>Address:&nbsp;&nbsp;</h4>
              <p>{info.address ? info.address : ""}</p>
            </div>
            <div className="patientDetails">
              <h4>Registeration Date:&nbsp;&nbsp;</h4>
              <p>{info.registerDate}</p>
            </div>
            <div className="patientDetails">
              <h4>Registered Pharmacists:&nbsp;&nbsp;</h4>
              <p>{info.pharmacist1 ? info.pharmacist1 : ""}</p>
              <p>{info.pharmacist2 ? `, ${info.pharmacist2}` : ""}</p>
              <p>{info.pharmacist3 ? `, ${info.pharmacist3}` : ""}</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "75%",
            marginTop: 40,
            marginBottom: 20,
          }}>
          <div className="button" onClick={() => setShowModal(true)}>
            Log Out
          </div>

          <Link to="/ForgotPassword" style={{ textDecoration: "none" }}>
            <div
              className="button"
              style={{ backgroundColor: "#09B13A", color: "#fff", textDecoration: "none" }}>
              Change Password
            </div>
          </Link>

          <div
            className="button"
            style={{ backgroundColor: "red" }}
            onClick={() => setShowDeleteModal(true)}>
            Delete Account
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        className="modal"
        ariaHideApp={false}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            backgroundColor: "#2872fa80",
          },
        }}>
        <div>
          <h2 style={{ color: "#2872fa" }}>Are you sure you want to log out?</h2>
          <div
            style={{
              display: "flex",
              marginTop: 50,
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className="button" onClick={logOut}>
              Yes
            </div>
            <div
              className="button"
              style={{ marginLeft: 20, backgroundColor: "red" }}
              onClick={() => setShowModal(false)}>
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        className="modal"
        ariaHideApp={false}
        onRequestClose={() => setShowDeleteModal(false)}
        style={{
          overlay: {
            backgroundColor: "#2872fa80",
          },
        }}>
        <div>
          <h3 style={{ color: "#2872fa" }}>Are you sure you want to delete your account?</h3>
          <div
            style={{
              display: "flex",
              marginTop: 50,
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className="button" onClick={deleteAccount}>
              Yes
            </div>
            <div
              className="button"
              style={{ marginLeft: 20, backgroundColor: "red" }}
              onClick={() => setShowDeleteModal(false)}>
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Background />
      <h3 style={{ fontSize: 40, opacity: 0 }}>.</h3>
    </div>
  );
}
