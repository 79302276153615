import { getAuth, signOut } from "firebase/auth";
import React from "react";
import Background from "./Background";

export default function RejectedUser() {
  const auth = getAuth();

  return (
    <div className="contentC" style={{ justifyContent: "center" }}>
      <h1 style={{ color: "#2872fa", fontSize: 40, marginBottom: 10 }}>
        Welcome to PH Health Connect
      </h1>
      <h3 style={{ color: "red" }}>Your account has been Rejected by the administrator</h3>
      <h3 style={{ color: "#2872fa", marginTop: 10 }}>
        For more information you can contact us through{" "}
        <a href="mailto:info@phhealthconnect.com">info@phhealthconnect.com</a>
      </h3>
      <div
        onClick={() => signOut(auth)}
        className="button"
        style={{ borderRadius: 25, marginTop: 20 }}>
        Log Out
      </div>
      <Background />
    </div>
  );
}
