import React from "react";

export default function MobileView() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#2872fa",
        zIndex: 999999,
        width: "100%",
        height: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        padding: 20,
      }}>
      <img src={require("../hcLogo.png")} style={{ width: 100, height: 100 }} alt="logo" />
      <h2 style={{ color: "#fff", textAlign: "center" }}>
        This Dashboard is accessible through Desktop only
      </h2>
      <p style={{ color: "#fff", textAlign: "center", marginTop: 30 }}>
        Please Note: If you are a patient, you need to log in from the mobile app
      </p>
    </div>
  );
}
