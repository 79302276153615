import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const auth = getAuth();

  function handleSubmit(event) {
    event.preventDefault();

    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setEmail("");
          toast.success("The password reset link has been sent successfully");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-email":
              setErrorMessage("Please enter a correct email address");
              break;
            case "auth/user-not-found":
              setErrorMessage("No account was found linked to this email address");
              break;
          }
        });
    }
  }

  return (
    <div className="formCenter">
      <form className="formFields" onSubmit={handleSubmit}>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="email">
            E-Mail Address
          </label>
          <input
            type="email"
            id="email"
            className="formFieldInput"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(txt) => setEmail(txt.target.value)}
          />
        </div>

        {errorMessage && (
          <div>
            <h3 style={{ color: "red", fontWeight: 500, marginBottom: 7 }}>{errorMessage}</h3>
          </div>
        )}

        <div className="formField">
          <button className="formFieldButton" onClick={handleSubmit}>
            Submit
          </button>

          <Link to="/" className="formFieldLink" style={{ marginLeft: 10 }}>
            Create an account
          </Link>
        </div>
      </form>
      <ToastContainer containerId="phid" draggable={false} />
    </div>
  );
}

export default ForgotPasswordForm;
