import React, { useEffect, useState } from "react";
import Background from "../componants/Background";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import CompleteProfile from "../componants/CompleteProfile";
import moment from "moment";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

export default function Completed() {
  const [missingInfo, setMissingInfo] = useState(false);
  const [requests, setRequests] = useState([]);
  const [rCount, setRCount] = useState(null);
  const [cCount, setCCount] = useState(null);

  const auth = getAuth();
  const db = getFirestore();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../homeDoc.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: require("../empty.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function getInfo() {
      const querySnapshot = await getDocs(
        query(
          collection(db, "pharmacies", auth.currentUser.email, "completed"),
          orderBy("timeStamp", "desc")
        )
      );
      setRequests(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );

      const getcCount = collection(db, "pharmacies", auth.currentUser.email, "completed");
      const ccount = await getCountFromServer(getcCount);
      setCCount(ccount.data().count);
    }

    getInfo();
  }, [requests]);

  return (
    <div className="contentC">
      <Background />
      <div style={{ display: "flex", flex: 1, marginTop: 120 }}>
        <div
          style={{
            backgroundColor: "#2872fa",
            borderRadius: 10,
            padding: 15,
            paddingLeft: 30,
            paddingRight: 30,
            transition: "1s",
            marginBottom: 23,
          }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
            <img src={require("../req.png")} style={{ width: 38, height: 38, marginRight: 10 }} />
            <h1 style={{ color: "#fff" }}>Completed Requests</h1>
          </div>
          {requests.map((request) => (
            <div key={request.id} className="request">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={request.data.pPhoto ? request.data.pPhoto : require("../avatar.png")}
                  className="pProfilePic"
                  style={{
                    width: 55,
                    height: 55,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                  }}
                />
                <div
                  style={{
                    height: 30,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                  <h3 style={{ color: "#000" }}>{request.data.pName}</h3>
                  <p style={{ fontSize: 12, margin: 0, color: "gray" }}>
                    {moment(request.data.timeStamp?.toDate()).locale("en").fromNow()}
                  </p>
                </div>
              </div>

              <p className="pDetails">
                <strong>Case:</strong> {request.data.form}
              </p>
              <p className="pDetails">
                <strong>City:</strong> {request.data.pCity}
              </p>
              <p className="pDetails">
                <strong>Date Of Birth:</strong>{" "}
                {request.data.pDateOfBirth.toDate().toDateString().substring(4)}
              </p>
              <div
                style={{
                  width: "50vw",
                  height: 45,
                  display: "flex",
                  borderRadius: 25,
                  overflow: "hidden",
                  marginTop: 20,
                  marginBottom: -10,
                  alignSelf: "center",
                }}>
                <Link
                  to={{
                    pathname: "/-Request",
                    state: request,
                  }}
                  className="vmdBTN"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}>
                  <p style={{ color: "#fff" }}>
                    <strong>View More Details</strong>
                  </p>
                </Link>
              </div>
            </div>
          ))}
          {cCount == 0 && (
            <div style={{ width: "45vw" }}>
              <h2 style={{ color: "#fff", marginTop: 70, textAlign: "center" }}>
                You currently don't have any Completed requests
              </h2>
              <Lottie options={defaultOptions2} height={350} width={350} style={{ opacity: 0.8 }} />
            </div>
          )}
        </div>
        <div
          style={{
            flex: 0.3,
            backgroundColor: "#2872fa",
            borderRadius: 10,
            padding: 15,
            marginLeft: 15,
            minWidth: "25vw",
            height: "80vh",
            position: "sticky",
            top: 100,
          }}>
          <img src={require("../chart.png")} />
          <h1 style={{ color: "#fff", marginBottom: 20 }}>Tasks Overview</h1>
          <p>
            <strong>Completed: </strong>
            {cCount + 0}
          </p>
          <Lottie options={defaultOptions} height={250} width={200} style={{ marginTop: 20 }} />
          <h2 style={{ color: "#fff", textAlign: "center", marginTop: -15 }}>
            Keep up the good work
          </h2>
        </div>
      </div>
    </div>
  );
}
