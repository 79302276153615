import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { getFirestore, collection, setDoc, doc } from "firebase/firestore";
import Splash from "../componants/Splash";

function SignUpForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [hasAgreed, setHasAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const auth = getAuth();
    let today = new Date().toLocaleDateString();

    if (name.length < 6) {
      setErrorMessage("The Pharmacy name must be 6 charechters or more");
    } else if (phone.length < 8) {
      setErrorMessage("Please enter a correct phone number");
    } else if (hasAgreed == false) {
      setErrorMessage("Please read and accept our Terms of Service");
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async () => {
          if (auth.currentUser) {
            setLoading(true);

            const db = getFirestore();
            await setDoc(doc(db, "pharmacies", auth.currentUser.email), {
              name: name,
              email: email,
              phone: phone,
              fax: fax,
              registerDate: today,
              accountStatus: "incomplete"
            }).then(() => {
              updateProfile(auth.currentUser, {
                displayName: name,
              });
              sendEmailVerification(auth.currentUser);
              setLoading(false);
            });
          }
        })
        .catch((error) => {
          console.log(error.code);
          switch (error.code) {
            case "auth/invalid-email":
              setErrorMessage("Please enter a correct email address");
              break;
            case "auth/invalid-password":
              setErrorMessage("The password must be at leat 6 characters long");
              break;
            case "auth/email-already-in-use":
              setErrorMessage("This email address is already in use");
              break;
            case "auth/internal-error":
              setErrorMessage("The password must be at leat 6 characters long");
              break;
          }
        });
    }
  }

  return (
    <div className="formCenter">
      <form onSubmit={handleSubmit} className="formFields">
        <div className="formField">
          <label className="formFieldLabel" htmlFor="name">
            Pharmacy Name
          </label>
          <input
            type="text"
            id="name"
            className="formFieldInput"
            placeholder="Enter your full name"
            name="name"
            value={name}
            onChange={(txt) => setName(txt.target.value)}
          />
        </div>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="email">
            E-Mail Address
          </label>
          <input
            type="email"
            id="email"
            className="formFieldInput"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(txt) => setEmail(txt.target.value)}
          />
        </div>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="formFieldInput"
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(txt) => setPassword(txt.target.value)}
          />
        </div>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="phone">
            Phone Number
          </label>
          <input
            type="phone"
            id="phone"
            className="formFieldInput"
            placeholder="Enter your Phone Number"
            name="phone"
            value={phone}
            onChange={(txt) => setPhone(txt.target.value)}
          />
        </div>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="phone">
            Fax Number
          </label>
          <input
            type="phone"
            id="tax"
            className="formFieldInput"
            placeholder="Enter your Phone Number"
            name="phone"
            value={fax}
            onChange={(txt) => setFax(txt.target.value)}
          />
        </div>

        <div className="formField">
          <label className="formFieldCheckboxLabel">
            <input
              className="formFieldCheckbox"
              type="checkbox"
              name="hasAgreed"
              value={hasAgreed}
              onChange={() => setHasAgreed(!hasAgreed)}
            />{" "}
            I agree to all statements in{" "}
            <a href="null" className="formFieldTermsLink">
              terms of service
            </a>
          </label>
        </div>

        {errorMessage && (
          <div>
            <h3 style={{ color: "red", fontWeight: 500, marginBottom: 7 }}>{errorMessage}</h3>
          </div>
        )}

        <div className="formField">
          <button className="formFieldButton">Sign Up</button>{" "}
          <Link to="/sign-in" className="formFieldLink">
            I'm already member
          </Link>
        </div>
      </form>
      {loading && <Splash />}
    </div>
  );
}

export default SignUpForm;
