import { getAuth, sendEmailVerification, signOut } from "firebase/auth";
import React from "react";
import Background from "./Background";

export default function VerifyEmail() {
  const auth = getAuth();

  const resendVerification = () => {
    sendEmailVerification(auth.currentUser);
  };

  return (
    <div className="contentC" style={{ justifyContent: "center" }}>
      <h1 style={{ color: "#2872fa", fontSize: 40, marginBottom: 10 }}>
        Welcome to PH Health Connect
      </h1>
      <h3>In order to access the Pharmacy Dashboard, you must verify your email address first</h3>
      <h3>
        The verification email was sent to your email address upon registeration, check it please
      </h3>
      <div
        onClick={() => signOut(auth)}
        className="button"
        style={{ borderRadius: 25, marginTop: 20 }}>
        Log Out
      </div>
      <div
        onClick={resendVerification}
        className="button"
        style={{ borderRadius: 25, marginTop: 20, width: 250 }}>
        Resend Verification Email
      </div>

      <h3 style={{ color: "red", marginTop: 25 }}>
        Please Note: If you are a patient, you need to log in from the mobile app
      </h3>
      <Background />
    </div>
  );
}
