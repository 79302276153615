import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Background from "../componants/Background";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

export default function CompletedRequest(props) {
  const [wait, setWait] = useState(false);
  const [phData, setPhData] = useState("");
  const [notes, setNotes] = useState("");
  const [choosePH, setChoosePH] = useState(false);
  const [selectedPH, setSelectedPH] = useState("");

  const auth = getAuth();
  const componentRef = useRef();
  const db = getFirestore();

  useEffect(() => {
    setTimeout(() => {
      setWait(true);
    }, 100);
  }, []);

  useEffect(() => {
    async function getPhInfo() {
      const docRef = doc(db, "pharmacies", auth.currentUser.email);
      const docSnap = await getDoc(docRef);
      setPhData(docSnap.data());
    }

    getPhInfo();

    return console.log("");
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `PH Health Connect - Request from ${props?.location?.state?.data?.pName}`,
  });

  return (
    <div className="contentC">
      <Background />
      {wait && (
        <div className="contentMC" style={{ backgroundColor: "#2872fa", marginBottom: 20 }}>
          <div style={{ width: "100%", padding: 10, marginBottom: 20 }}>
            <h1 style={{ textAlign: "center", color: "#fff" }}>
              Request From:{" "}
              {props?.location?.state?.data?.pName
                ? props?.location?.state?.data?.pName
                : "Undefined"}
            </h1>
          </div>
          <div className="request" style={{ width: "55vw" }} ref={componentRef}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  props?.location?.state?.data?.pPhoto
                    ? props?.location?.state?.data?.pPhoto
                    : require("../avatar.png")
                }
                className="pProfilePic"
                style={{
                  width: 55,
                  height: 55,
                  borderRadius: 50,
                  resize: "cover",
                  marginRight: 10,
                }}
              />
              <div
                style={{
                  height: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                <h3 style={{ color: "#000" }}>
                  {props?.location?.state?.data?.pName
                    ? props?.location?.state?.data?.pName
                    : "Undefined"}
                </h3>
                <p style={{ fontSize: 12, margin: 0, color: "gray" }}>
                  {props?.location?.state?.data?.timeStamp
                    ? moment(props?.location?.state?.data?.timeStamp?.toDate())
                        .locale("en")
                        .fromNow()
                    : "Undefined"}
                </p>
              </div>
            </div>

            <p className="pDetails">
              <strong>Case:</strong>{" "}
              {props?.location?.state?.data?.form
                ? props?.location?.state?.data?.form
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>City:</strong>{" "}
              {props?.location?.state?.data?.pCity
                ? props?.location?.state?.data?.pCity
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>Address:</strong>{" "}
              {props?.location?.state?.data?.pAddress
                ? props?.location?.state?.data?.pAddress
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>Phone Number:</strong>{" "}
              {props?.location?.state?.data?.pPhone
                ? props?.location?.state?.data?.pPhone
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>Date Of Birth:</strong>{" "}
              {props?.location?.state?.data?.pDateOfBirth
                ? props?.location?.state?.data?.pDateOfBirth.toDate().toDateString().substring(4)
                : "Undefined"}
            </p>
            <div style={{ width: "90%", alignSelf: "center", marginTop: 20 }}>
              <h1 style={{ textAlign: "center", color: "#2872fa", marginBottom: 20 }}>
                Questionnaire Results
              </h1>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question One:</strong>{" "}
                {props?.location?.state?.data?.q1 ? props?.location?.state?.data?.q1 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a1 ? props?.location?.state?.data?.a1 : "Undefined"}
              </p>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question Two:</strong>{" "}
                {props?.location?.state?.data?.q2 ? props?.location?.state?.data?.q2 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a2 ? props?.location?.state?.data?.a2 : "Undefined"}
              </p>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question Three:</strong>{" "}
                {props?.location?.state?.data?.q3 ? props?.location?.state?.data?.q3 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a3 ? props?.location?.state?.data?.a3 : "Undefined"}
              </p>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question Four:</strong>{" "}
                {props?.location?.state?.data?.q4 ? props?.location?.state?.data?.q4 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a4 ? props?.location?.state?.data?.a4 : "Undefined"}
              </p>
              {props?.location?.state?.data?.q5 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Five:</strong>{" "}
                    {props?.location?.state?.data?.q5
                      ? props?.location?.state?.data?.q5
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a5
                      ? props?.location?.state?.data?.a5
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q6 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Six:</strong>{" "}
                    {props?.location?.state?.data?.q6
                      ? props?.location?.state?.data?.q6
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a6
                      ? props?.location?.state?.data?.a6
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q7 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Seven:</strong>{" "}
                    {props?.location?.state?.data?.q7
                      ? props?.location?.state?.data?.q7
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a7
                      ? props?.location?.state?.data?.a7
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q8 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Eight:</strong>{" "}
                    {props?.location?.state?.data?.q8
                      ? props?.location?.state?.data?.q8
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a8
                      ? props?.location?.state?.data?.a8
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q9 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Nine:</strong>{" "}
                    {props?.location?.state?.data?.q9
                      ? props?.location?.state?.data?.q9
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a9
                      ? props?.location?.state?.data?.a9
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q10 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Ten:</strong>{" "}
                    {props?.location?.state?.data?.q10
                      ? props?.location?.state?.data?.q10
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a10
                      ? props?.location?.state?.data?.a10
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q11 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Eleven:</strong>{" "}
                    {props?.location?.state?.data?.q11
                      ? props?.location?.state?.data?.q11
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a11
                      ? props?.location?.state?.data?.a11
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q12 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Twelve:</strong>{" "}
                    {props?.location?.state?.data?.q12
                      ? props?.location?.state?.data?.q12
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a12
                      ? props?.location?.state?.data?.a12
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q13 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Thirteen:</strong>{" "}
                    {props?.location?.state?.data?.q13
                      ? props?.location?.state?.data?.q13
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a13
                      ? props?.location?.state?.data?.a13
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q14 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Fourteen:</strong>{" "}
                    {props?.location?.state?.data?.q14
                      ? props?.location?.state?.data?.q14
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a14
                      ? props?.location?.state?.data?.a14
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q15 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Fifteen:</strong>{" "}
                    {props?.location?.state?.data?.q15
                      ? props?.location?.state?.data?.q15
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a15
                      ? props?.location?.state?.data?.a15
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q16 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Sixteen:</strong>{" "}
                    {props?.location?.state?.data?.q16
                      ? props?.location?.state?.data?.q16
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a16
                      ? props?.location?.state?.data?.a16
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q17 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Seventeen:</strong>{" "}
                    {props?.location?.state?.data?.q17
                      ? props?.location?.state?.data?.q17
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a17
                      ? props?.location?.state?.data?.a17
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q18 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Eighteen:</strong>{" "}
                    {props?.location?.state?.data?.q18
                      ? props?.location?.state?.data?.q18
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a18
                      ? props?.location?.state?.data?.a18
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.attachedPic && (
                <div>
                  <p className="pDetails" style={{ marginBottom: 10 }}>
                    <strong style={{ color: "red" }}>Attched Picture:</strong>
                  </p>
                  <img
                    src={props?.location?.state?.data?.attachedPic}
                    style={{ width: 300, height: 250, objectFit: "cover", borderRadius: 10 }}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              width: "50vw",
              height: 45,
              display: "flex",
              borderRadius: 25,
              overflow: "hidden",
              marginTop: 20,
              marginBottom: 0,
              alignSelf: "center",
            }}>
            <Link
              to="/Home"
              className="macBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "#fff",
                textDecoration: "none",
              }}>
              <p>
                <strong>Return to Home Page</strong>
              </p>
            </Link>
            <div
              onClick={handlePrint}
              className="vmdBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}>
              <p style={{ color: "#fff" }}>
                <strong>Export As PDF</strong>
              </p>
              <img
                src={require("../export-pdf.png")}
                style={{ width: 33, height: 33, objectFit: "cover", marginLeft: 5 }}
              />
            </div>
          </div>
        </div>
      )}
      <h3 style={{ fontSize: 1 }}>.</h3>
    </div>
  );
}
