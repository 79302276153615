import React, { useEffect, useState } from "react";
import Background from "../componants/Background";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import CompleteProfile from "../componants/CompleteProfile";
import moment from "moment";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

export default function Home() {
  const [missingInfo, setMissingInfo] = useState(false);
  const [requests, setRequests] = useState([]);
  const [rCount, setRCount] = useState(null);
  const [cCount, setCCount] = useState(null);
  const [rjCount, setRjCount] = useState(null);
  const [pending, setPending] = useState(false);

  const auth = getAuth();
  const db = getFirestore();
  let today = new Date().toLocaleDateString();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../homeDoc.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: require("../empty.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function getInfo() {
      const docRef = doc(db, "pharmacies", auth.currentUser.email);
      const docSnap = await getDoc(docRef);
      if (docSnap?.data()?.accountStatus == "incomplete") {
        setMissingInfo(true);
      }
      if (docSnap?.data()?.accountStatus == "pending") {
        setPending(true);
      }
      if (docSnap.exists()) {
        const querySnapshot = await getDocs(
          query(
            collection(db, "pharmacies", auth.currentUser.email, "requests"),
            orderBy("timeStamp", "desc")
          )
        );
        setRequests(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        const getrCount = collection(db, "pharmacies", auth.currentUser.email, "requests");
        const getcCount = collection(db, "pharmacies", auth.currentUser.email, "completed");
        const getrjCount = collection(db, "pharmacies", auth.currentUser.email, "rejected");
        const rcount = await getCountFromServer(getrCount);
        const ccount = await getCountFromServer(getcCount);
        const rjcount = await getCountFromServer(getrjCount);
        setRCount(rcount.data().count);
        setCCount(ccount.data().count);
        setRjCount(rjcount.data().count);
      }
    }

    getInfo();
  }, []);

  const markAsCompleted = async (request) => {
    const newDocRef = await addDoc(
      collection(db, "pharmacies", auth.currentUser.email, "completed"),
      {
        dateOfProcess: today,
        pName: request.data.pName,
        pEmail: request.data.pEmail,
        pPhone: request.data.pPhone,
        pPhoto: request.data.pPhoto,
        pAddress: request.data.pAddress,
        pCity: request.data.pCity,
        pDateOfBirth: request.data.pDateOfBirth,
        pPostalCode: request.data.pPostalCode,
        timeStamp: request.data.timeStamp,
        form: request.data.form,
        attachedPic: request.data.attachedPic ? request.data.attachedPic : null,
        q1: request.data.q1,
        a1: request.data.a1,
        q2: request.data.q2,
        a2: request.data.a2,
        q3: request.data.q3,
        a3: request.data.a3,
        q4: request.data.q4,
        a4: request.data.a4,
        q5: request.data.q5 ? request.data.q5 : null,
        a5: request.data.a5 ? request.data.a5 : null,
        q6: request.data.q6 ? request.data.q6 : null,
        a6: request.data.a6 ? request.data.a6 : null,
        q7: request.data.q7 ? request.data.q7 : null,
        a7: request.data.a7 ? request.data.a7 : null,
        q8: request.data.q8 ? request.data.q8 : null,
        a8: request.data.a8 ? request.data.a8 : null,
        q9: request.data.q9 ? request.data.q9 : null,
        a9: request.data.a9 ? request.data.a9 : null,
        q10: request.data.q10 ? request.data.q10 : null,
        a10: request.data.a10 ? request.data.a10 : null,
        q11: request.data.q11 ? request.data.q11 : null,
        a11: request.data.a11 ? request.data.a11 : null,
        q12: request.data.q12 ? request.data.q12 : null,
        a12: request.data.a12 ? request.data.a12 : null,
        q13: request.data.q13 ? request.data.q13 : null,
        a13: request.data.a13 ? request.data.a13 : null,
        q14: request.data.q14 ? request.data.q14 : null,
        a14: request.data.a14 ? request.data.a14 : null,
        q15: request.data.q15 ? request.data.q15 : null,
        a15: request.data.a15 ? request.data.a15 : null,
        q16: request.data.q16 ? request.data.q16 : null,
        a16: request.data.a16 ? request.data.a16 : null,
        q17: request.data.q17 ? request.data.q17 : null,
        a17: request.data.a17 ? request.data.a17 : null,
        q18: request.data.q18 ? request.data.q18 : null,
        a18: request.data.a18 ? request.data.a18 : null,
        q19: request.data.q19 ? request.data.q19 : null,
        a19: request.data.a19 ? request.data.a19 : null,
      }
    ).then(async () => {
      await deleteDoc(doc(db, "pharmacies", auth.currentUser.email, "requests", request.id));
    });
  };

  return (
    <div className="contentC">
      <Background />
      {missingInfo ? (
        <CompleteProfile />
      ) : (
        <>
          <div style={{ display: "flex", flex: 1, marginTop: 120 }}>
            <div
              style={{
                backgroundColor: "#2872fa",
                borderRadius: 10,
                padding: 15,
                paddingLeft: 30,
                paddingRight: 30,
                transition: "1s",
                marginBottom: 23,
              }}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                <img
                  src={require("../req.png")}
                  style={{ width: 38, height: 38, marginRight: 10 }}
                />
                <h1 style={{ color: "#fff" }}>Requests</h1>
              </div>
              {requests.map((request) => (
                <div key={request.id} className="request">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={request.data.pPhoto ? request.data.pPhoto : require("../avatar.png")}
                      className="pProfilePic"
                      style={{
                        width: 55,
                        height: 55,
                        borderRadius: 50,
                        resize: "cover",
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        height: 30,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}>
                      <h3 style={{ color: "#000" }}>{request.data.pName}</h3>
                      <p style={{ fontSize: 12, margin: 0, color: "gray" }}>
                        {moment(request.data.timeStamp?.toDate()).locale("en").fromNow()}
                      </p>
                    </div>
                  </div>

                  <p className="pDetails">
                    <strong>Case:</strong> {request.data.form}
                  </p>
                  <p className="pDetails">
                    <strong>City:</strong> {request.data.pCity}
                  </p>
                  <p className="pDetails">
                    <strong>Date Of Birth:</strong>{" "}
                    {request.data.pDateOfBirth.toDate().toDateString().substring(4)}
                  </p>
                  <div
                    style={{
                      width: "50vw",
                      height: 45,
                      display: "flex",
                      borderRadius: 25,
                      overflow: "hidden",
                      marginTop: 20,
                      marginBottom: -10,
                      alignSelf: "center",
                    }}>
                    <div
                      onClick={() => markAsCompleted(request)}
                      className="macBTN"
                      style={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}>
                      <p>
                        <strong>Mark As Completed</strong>
                      </p>
                    </div>
                    <Link
                      to={{
                        pathname: "/Request",
                        state: request,
                      }}
                      className="vmdBTN"
                      style={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}>
                      <p style={{ color: "#fff" }}>
                        <strong>View More Details</strong>
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
              {rCount == 0 && (
                <div style={{ width: "45vw" }}>
                  <h2 style={{ color: "#fff", marginTop: 70, textAlign: "center" }}>
                    You currently don't have any requests
                  </h2>
                  <Lottie
                    options={defaultOptions2}
                    height={350}
                    width={350}
                    style={{ opacity: 0.8 }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                flex: 0.3,
                backgroundColor: "#2872fa",
                borderRadius: 10,
                padding: 15,
                marginLeft: 15,
                minWidth: "25vw",
                height: "80vh",
                position: "sticky",
                top: 100,
              }}>
              <img src={require("../chart.png")} />
              <h1 style={{ color: "#fff", marginBottom: 20 }}>Tasks Overview</h1>
              <p style={{ marginTop: 5 }}>
                <strong>Total Tasks Number: </strong>
                {rCount + cCount + rjCount}
              </p>
              <p style={{ marginTop: 5 }}>
                <strong>Requests: </strong>
                {rCount + 0}
              </p>
              <Link to="/Completed" className="nLink">
                <p style={{ marginTop: -22 }}>
                  <strong>Completed: </strong>
                  {cCount + 0}
                </p>
              </Link>
              <Link to="/Rejected" className="nLink">
                <p style={{ marginTop: -22 }}>
                  <strong>Rejected: </strong>
                  {rjCount + 0}
                </p>
              </Link>
              <Lottie options={defaultOptions} height={230} width={190} style={{ marginTop: 0 }} />
              <h2 style={{ color: "#fff", textAlign: "center", marginTop: -15 }}>
                Keep up the good work
              </h2>
            </div>
          </div>
        </>
      )}
      {pending && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            className="request"
            style={{
              width: "65vw",
              cursor: "default",
              minHeight: "40vh",
              height: "80vh",
              overflowY: "scroll",
              padding: 15,
              display: "flex",
              alignItems: "center",
              marginTop: "10vh",
              backgroundColor: "#2872fa",
              position: "relative",
              justifyContent: "center",
            }}>
            <h1 style={{ marginTop: -50, color: "#FFD100", alignSelf: "center" }}>
              Pending Review
            </h1>
            <h2 style={{ marginTop: 60, color: "#fff", alignSelf: "center" }}>
              Dear {auth.currentUser.displayName}
            </h2>
            <h2 style={{ color: "#fff", alignSelf: "center", fontWeight: "400", fontSize: 20 }}>
              Your account is currently being reviewed by the administrator
            </h2>
            <h2 style={{ color: "#fff", alignSelf: "center", fontWeight: "400", fontSize: 20 }}>
              You will be able to access your dashboard fully once your account is activated
            </h2>
            <h2 style={{ color: "#fff", alignSelf: "center", fontWeight: "400", fontSize: 20 }}>
              We thank you for your patience and understanding
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}
