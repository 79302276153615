import React, { useEffect, useRef, useState } from "react";
import Background from "../componants/Background";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  onSnapshot,
  where,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Lottie from "react-lottie";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Index,
  Configure,
} from "react-instantsearch-hooks-web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//algolia credentials
const searchClient = algoliasearch("EW0U9EKMBK", "e3297bbe9d287066fcc63f4c0ec83382");

export default function Admin() {
  const [actionUser, setActionUser] = useState(null);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pCount, setPCount] = useState(null);
  const [phCount, setPhCount] = useState(null);
  const [totalRequestsCount, setTotalRequestsCount] = useState(null);
  const [completedRequestsCount, setCompletedRequestsCount] = useState(null);
  const [rejectedRequestsCount, setRejectedRequestsCount] = useState(null);
  const [todayRequestsCount, setTodayRequestsCount] = useState(null);
  const [pendingUsersModal, setPendingUsersModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [deleteUserDataModal, setDeleteUserDataModal] = useState(false);
  const [unbanUserModal, setUnbanUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pendingCount, setPendingCount] = useState(null);
  const [analyticsModal, setAnalyticsModal] = useState(false);

  const auth = getAuth();
  const db = getFirestore();
  let today = new Date().toLocaleDateString();
  const q = query(collection(db, "pharmacies"), where("accountStatus", "==", "pending"));
  const bannedNotif = () => toast.success("The user has been banned successfully");
  const unbannedNotif = () => toast.success("The user has been unbanned successfully");
  const activatedNotif = () => toast.success("The user has been activated successfully");
  const rejectedNotif = () => toast.success("The user has been rejected successfully");
  const deletedDataNotif = () => toast.success("The user's data has been deleted successfully");

  const wrapperRef = useRef(null);
  const wrapperRefTwo = useRef(null);
  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(wrapperRefTwo);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPendingUsersModal(false);
          setAnalyticsModal(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../loading.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function getInfo() {
      const getpCount = collection(db, "patients");
      const getPhCount = collection(db, "pharmacies");
      const pcount = await getCountFromServer(getpCount);
      const phcount = await getCountFromServer(getPhCount);
      const pendingCount = await getCountFromServer(q);
      setPCount(pcount.data().count);
      setPhCount(phcount.data().count);
      setPendingCount(pendingCount.data().count);
    }

    getInfo();
  }, []);

  async function getAnalytics(hit) {
    setLoading(true);
    const getRequestCount = collection(db, "pharmacies", hit.email, "requests");
    const getCompletedRequestCount = collection(db, "pharmacies", hit.email, "completed");
    const getRejectedRequestCount = collection(db, "pharmacies", hit.email, "rejected");
    const todayQ = query(
      collection(db, "pharmacies", hit.email, "completed"),
      where("dateOfProcess", "==", today)
    );

    const req = await getCountFromServer(getRequestCount);
    const comp = await getCountFromServer(getCompletedRequestCount);
    const rej = await getCountFromServer(getRejectedRequestCount);
    const tod = await getCountFromServer(todayQ);
    setTotalRequestsCount(req.data().count);
    setCompletedRequestsCount(comp.data().count);
    setRejectedRequestsCount(rej.data().count);
    setTodayRequestsCount(tod.data().count);
    setLoading(false);
  }

  function Hit({ hit }) {
    return (
      <div>
        <div className="patientListItem">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={hit.profilePictur ? hit.profilePictur : require("../avatar.png")}
              style={{
                width: 45,
                height: 45,
                borderRadius: 50,
                resize: "cover",
                marginRight: 10,
                border: "solid",
                borderColor: "#2872fa",
                borderWidth: 1.7,
              }}
            />
            <h2 style={{ color: "#000" }}>{hit.name}</h2>
          </div>
          <div style={{ color: "#444", paddingLeft: 10, marginTop: 10 }}>
            <div className="patientDetails">
              <h4>Account Type:&nbsp;&nbsp;</h4>
              <p>Patient</p>
            </div>
            <div className="patientDetails">
              <h4>Date Of Birth:&nbsp;&nbsp;</h4>
              <p>{new Date(hit.dateOfBirth).toLocaleDateString()}</p>
            </div>
            <div className="patientDetails">
              <h4>City:&nbsp;&nbsp;</h4>
              <p>{hit.city}</p>
            </div>
            <div className="patientDetails">
              <h4>Phone Number:&nbsp;&nbsp;</h4>
              <p>{hit.phoneNumber}</p>
            </div>
            <div className="patientDetails">
              <h4>Email:&nbsp;&nbsp;</h4>
              <p>{hit.email}</p>
            </div>
            <div className="patientDetails">
              <h4>Postal Code:&nbsp;&nbsp;</h4>
              <p>{hit.postalCode}</p>
            </div>
            <div className={"patientDetails addressD"}>
              <h4>Address:&nbsp;&nbsp;</h4>
              <p>{hit.address}</p>
            </div>
          </div>
          <div
            style={{
              width: "50vw",
              height: 45,
              display: "flex",
              borderRadius: 25,
              overflow: "hidden",
              marginTop: 20,
              alignSelf: "center",
            }}>
            <div
              onClick={() => {
                setDeleteUserModal(true);
                setActionUser(hit);
              }}
              className="rejBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}>
              <p style={{ color: "#fff", fontSize: 16 }}>
                <strong>Ban User</strong>
              </p>
            </div>
            <div
              onClick={() => {
                setDeleteUserDataModal(true);
                setActionUser(hit);
              }}
              className="vmdBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}>
              <p style={{ color: "#fff", fontSize: 16 }}>
                <strong>Delete User Data</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function HitPH({ hit }) {
    return (
      <div>
        <div className="patientListItem">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../docAvatar.png")}
              style={{
                width: 45,
                height: 45,
                borderRadius: 50,
                resize: "cover",
                marginRight: 10,
                border: "solid",
                borderColor: "#2872fa",
                borderWidth: 1.7,
              }}
            />
            <h2 style={{ color: "#000" }}>{hit.name}</h2>
            {hit.accountStatus == "active" && (
              <img
                src={require("../ver.png")}
                style={{ width: 20, height: 20, objectFit: "cover", marginLeft: 3 }}
              />
            )}
          </div>
          <div style={{ color: "#444", paddingLeft: 10, marginTop: 10 }}>
            <div className="patientDetails">
              <h4>Account Type:&nbsp;&nbsp;</h4>
              <p>Pharmacist</p>
            </div>
            <div className="patientDetails">
              <h4>Account Status:&nbsp;&nbsp;</h4>
              {hit.accountStatus == "pending" && (
                <p style={{ color: "#FFA500", fontWeight: "600" }}>Pending</p>
              )}
              {hit.accountStatus == "banned" && (
                <p style={{ color: "red", fontWeight: "600" }}>Banned</p>
              )}
              {hit.accountStatus == "active" && (
                <p style={{ color: "#00c93c", fontWeight: "600" }}>Active</p>
              )}
              {hit.accountStatus == "incomplete" && (
                <p style={{ color: "gray", fontWeight: "600" }}>Incomplete</p>
              )}
              {hit.accountStatus == "rejected" && (
                <p style={{ color: "red", fontWeight: "600" }}>Rejected</p>
              )}
            </div>
            <div className="patientDetails">
              <h4>Registration Date:&nbsp;&nbsp;</h4>
              <p>{hit.registerDate}</p>
            </div>
            <div className="patientDetails">
              <h4>Phone Number:&nbsp;&nbsp;</h4>
              <p>{hit.phone}</p>
            </div>
            <div className="patientDetails">
              <h4>Fax Number:&nbsp;&nbsp;</h4>
              <p>{hit.fax}</p>
            </div>
            <div className="patientDetails">
              <h4>Email:&nbsp;&nbsp;</h4>
              <p>{hit.email}</p>
            </div>
            <div className={"patientDetails"}>
              <h4>Address:&nbsp;&nbsp;</h4>
              <p>{hit.address}</p>
            </div>
            <div className="patientDetails">
              <h4>Assigned Pharmacists:&nbsp;&nbsp;</h4>
            </div>
            <div>
              <p>
                <strong>Pharmacist:</strong> {hit.pharmacist1 + ", " + hit.pharmacist1N}
              </p>
              {hit.pharmacist2 && (
                <p>
                  <strong>Pharmacist:</strong> {hit.pharmacist2 + ", " + hit.pharmacist2N}
                </p>
              )}
              {hit.pharmacist3 && (
                <p>
                  <strong>Pharmacist:</strong> {hit.pharmacist3 + ", " + hit.pharmacist3N}
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              width: "50vw",
              height: 45,
              display: "flex",
              borderRadius: 25,
              overflow: "hidden",
              marginTop: 20,
              alignSelf: "center",
            }}>
            {hit.accountStatus == "banned" ? (
              <div
                onClick={() => {
                  setUnbanUserModal(true);
                  setActionUser(hit);
                }}
                className="macBTN"
                style={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}>
                <p style={{ color: "#fff", fontSize: 16 }}>
                  <strong>Unban User</strong>
                </p>
              </div>
            ) : (
              <div
                onClick={() => {
                  setDeleteUserModal(true);
                  setActionUser(hit);
                }}
                className="rejBTN"
                style={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}>
                <p style={{ color: "#fff", fontSize: 16 }}>
                  <strong>Ban User</strong>
                </p>
              </div>
            )}
            <div
              onClick={() => {
                setDeleteUserDataModal(true);
                setActionUser(hit);
              }}
              className="vmdBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}>
              <p style={{ color: "#fff", fontSize: 16 }}>
                <strong>Reset User Data</strong>
              </p>
            </div>
            <div
              onClick={() => {
                setAnalyticsModal(true);
                setActionUser(hit);
                getAnalytics(hit);
              }}
              className="vaBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}>
              <p style={{ color: "#fff", fontSize: 16 }}>
                <strong>View Analytics</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getPendingUsers = async () => {
    setPendingUsersModal(true);
    setLoading(true);
    const querySnapshot = await getDocs(q);

    setPendingUsers(
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }))
    );

    setLoading(false);
  };

  const activateUser = async (item) => {
    setLoading(true);
    const clickedUser = doc(db, "pharmacies", item.data.email);

    await updateDoc(clickedUser, {
      accountStatus: "active",
    });

    activatedNotif();
    getPendingUsers();
    const pendingCount = await getCountFromServer(q);
    setPendingCount(pendingCount.data().count);
  };

  const rejectUser = async (item) => {
    setLoading(true);
    const clickedUser = doc(db, "pharmacies", item.data.email);

    await updateDoc(clickedUser, {
      accountStatus: "rejected",
    });

    rejectedNotif();
    getPendingUsers();
    const pendingCount = await getCountFromServer(q);
    setPendingCount(pendingCount.data().count);
  };

  const deleteUserData = async () => {
    
      const clickedUser = doc(db, "pharmacies", actionUser.email);

      await updateDoc(clickedUser, {
        accountStatus: "incomplete",
      });

    deletedDataNotif();
    setDeleteUserDataModal(false);
  };

  const banUser = async () => {
    const clickedUser = doc(db, "pharmacies", actionUser.email);

    await updateDoc(clickedUser, {
      accountStatus: "banned",
    });

    bannedNotif();
    setDeleteUserModal(false);
  };

  const unbanUser = async () => {
    const clickedUser = doc(db, "pharmacies", actionUser.email);

    await updateDoc(clickedUser, {
      accountStatus: "active",
    });

    unbannedNotif();
    setUnbanUserModal(false);
  };

  return (
    <div className="contentC">
      <Background />
      {auth.currentUser.email == "markmhr96@gmail.com" || "mouayad.alkallas@hotmail.com" ? (
        <div style={{ display: "flex", flex: 1, marginTop: 120 }}>
          <div
            style={{
              backgroundColor: "#2872fa",
              borderRadius: 10,
              padding: 15,
              paddingLeft: 30,
              paddingRight: 30,
              transition: "1s",
              marginBottom: 23,
              minWidth: "55vw",
            }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
              <img
                src={require("../admin.png")}
                style={{ width: 45, height: 45, marginRight: 10 }}
              />
              <h1 style={{ color: "#fff" }}>Admin Dashboard</h1>
            </div>

            <InstantSearch searchClient={searchClient} indexName="patients">
              <Configure hitsPerPage={4} />
              <SearchBox autoFocus placeholder="Search Users..." />
              <Index indexName="pharmacies">
                <Hits hitComponent={HitPH} />
              </Index>
              <Hits hitComponent={Hit} />
            </InstantSearch>
          </div>
          <div
            style={{
              flex: 0.3,
              backgroundColor: "#2872fa",
              borderRadius: 10,
              padding: 15,
              marginLeft: 15,
              minWidth: "25vw",
              height: "80vh",
              position: "sticky",
              top: 100,
            }}>
            <img src={require("../chart.png")} />
            <h1 style={{ color: "#fff", marginBottom: 10 }}>Analytics</h1>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flex: 1,
                height: "60%",
                justifyContent: "space-between",
              }}>
              <div className="analyticsBox" style={{ backgroundColor: "#1AADFC" }}>
                <p style={{ position: "absolute", top: 15, left: 15 }}>
                  <strong>Total Users</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{pCount + phCount + 0}</p>
              </div>
              <div className="analyticsBox" style={{ backgroundColor: "#0DCF47" }}>
                <p style={{ position: "absolute", top: 15, left: 15 }}>
                  <strong>Pharmacies</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{phCount + 0}</p>
              </div>
              <div className="analyticsBox" style={{ backgroundColor: "#9752f3" }}>
                <p style={{ position: "absolute", top: 15, left: 15 }}>
                  <strong>Patients</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{pCount + 0}</p>
              </div>
              <div
                className="analyticsBox"
                style={{ backgroundColor: "#cd1212", cursor: "pointer" }}
                onClick={getPendingUsers}>
                <p style={{ position: "absolute", top: 15, left: 15 }}>
                  <strong>Pending Users</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{pendingCount + 0}</p>
              </div>
            </div>
            <div
              className="analyticsBox"
              style={{
                width: "100%",
                height: 55,
                borderRadius: 10,
                backgroundColor: "#1FA6EF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}>
              <p>
                <strong>Maintenance: </strong>
                <strong>STABLE</strong>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <h2 style={{ fontSize: 100, alignSelf: "center", color: "#2872fa" }}>Error 403</h2>
          <h1>You don't have access to this page</h1>
        </div>
      )}
      {pendingUsersModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "zoom-out",
          }}>
          <div
            ref={wrapperRef}
            className="request"
            style={{
              width: "65vw",
              cursor: "default",
              minHeight: "40vh",
              height: "80vh",
              overflowY: "scroll",
              padding: 15,
              display: "flex",
              alignItems: "center",
              marginTop: "10vh",
              backgroundColor: "#2872fa",
              position: "relative",
            }}>
            <h1 style={{ marginBottom: 30, color: "#fff", alignSelf: "center" }}>Pending Users</h1>
            {pendingUsers.length > 0 ? (
              <>
                {pendingUsers.map((item, index) => (
                  <div className="patientListItem" style={{ marginBottom: 15 }} key={index}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={require("../docAvatar.png")}
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: 50,
                          resize: "cover",
                          marginRight: 10,
                          border: "solid",
                          borderColor: "#2872fa",
                          borderWidth: 1.7,
                        }}
                      />
                      <h2 style={{ color: "#000" }}>{item.data.name}</h2>
                    </div>
                    <div style={{ color: "#444", paddingLeft: 10, marginTop: 10 }}>
                      <div className="patientDetails">
                        <h4>Account Type:&nbsp;&nbsp;</h4>
                        <p>Pharmacist</p>
                      </div>
                      <div className="patientDetails">
                        <h4>Account Status:&nbsp;&nbsp;</h4>
                        {item.data.accountStatus == "pending" && (
                          <p style={{ color: "#FFA500", fontWeight: "600" }}>Pending</p>
                        )}
                      </div>
                      <div className="patientDetails">
                        <h4>Registration Date:&nbsp;&nbsp;</h4>
                        <p>{item.data.registerDate}</p>
                      </div>
                      <div className="patientDetails">
                        <h4>Phone Number:&nbsp;&nbsp;</h4>
                        <p>{item.data.phone}</p>
                      </div>
                      <div className="patientDetails">
                        <h4>Fax Number:&nbsp;&nbsp;</h4>
                        <p>{item.data.fax}</p>
                      </div>
                      <div className="patientDetails">
                        <h4>Email:&nbsp;&nbsp;</h4>
                        <p>{item.data.email}</p>
                      </div>
                      <div className={"patientDetails"}>
                        <h4>Address:&nbsp;&nbsp;</h4>
                        <p>{item.data.address}</p>
                      </div>
                      <div className="patientDetails">
                        <h4>Assigned Pharmacists:&nbsp;&nbsp;</h4>
                      </div>
                      <div>
                        <p>
                          <strong>Pharmacist:</strong>{" "}
                          {item.data.pharmacist1 + ", " + item.data.pharmacist1N}
                        </p>
                        {item.data.pharmacist2 && (
                          <p>
                            <strong>Pharmacist:</strong>{" "}
                            {item.data.pharmacist2 + ", " + item.data.pharmacist2N}
                          </p>
                        )}
                        {item.data.pharmacist3 && (
                          <p>
                            <strong>Pharmacist:</strong>{" "}
                            {item.data.pharmacist3 + ", " + item.data.pharmacist3N}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50vw",
                        height: 45,
                        display: "flex",
                        borderRadius: 25,
                        overflow: "hidden",
                        marginTop: 20,
                        alignSelf: "center",
                      }}>
                      <div
                        onClick={() => activateUser(item)}
                        className="macBTN"
                        style={{
                          width: "50%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}>
                        <p style={{ color: "#fff", fontSize: 16 }}>
                          <strong>Activate User</strong>
                        </p>
                      </div>
                      <div
                        onClick={() => rejectUser(item)}
                        className="rejBTN"
                        style={{
                          width: "50%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}>
                        <p style={{ color: "#fff", fontSize: 16 }}>
                          <strong>Reject User</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <h2 style={{ alignSelf: "center", marginTop: 110, color: "#fff" }}>
                You don't have any pending users at the moment
              </h2>
            )}
            {loading && (
              <div className="splash">
                <Lottie options={defaultOptions} height={100} width={100} />
              </div>
            )}
          </div>
        </div>
      )}
      {deleteUserModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div className="request" style={{ width: "40vw", cursor: "default", zIndex: 98 }}>
            <h2 style={{ marginBottom: 10, color: "red", alignSelf: "center" }}>
              Are you sure you want to delete this user?
            </h2>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 20,
                marginBottom: 20,
              }}>
              {actionUser.fax ? (
                <img
                  src={require("../docAvatar.png")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              ) : (
                <img
                  src={
                    actionUser.profilePictur ? actionUser.profilePictur : require("../avatar.png")
                  }
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              )}
              <h2 style={{ color: "#000" }}>{actionUser.name}</h2>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "80%",
                marginTop: 20,
                alignSelf: "center",
              }}>
              <div className="button" onClick={banUser}>
                Confirm
              </div>
              <div
                className="button"
                style={{ backgroundColor: "red" }}
                onClick={() => setDeleteUserModal(false)}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteUserDataModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div className="request" style={{ width: "40vw", cursor: "default", zIndex: 98 }}>
            <h2
              style={{ marginBottom: 10, color: "red", alignSelf: "center", textAlign: "center" }}>
              Are you sure you want to delete this user's data?
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 20,
                marginBottom: 20,
              }}>
              {actionUser.fax ? (
                <img
                  src={require("../docAvatar.png")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              ) : (
                <img
                  src={
                    actionUser.profilePictur ? actionUser.profilePictur : require("../avatar.png")
                  }
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              )}
              <h2 style={{ color: "#000" }}>{actionUser.name}</h2>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "80%",
                marginTop: 20,
                alignSelf: "center",
              }}>
              <div className="button" onClick={deleteUserData}>
                Confirm
              </div>
              <div
                className="button"
                style={{ backgroundColor: "red" }}
                onClick={() => setDeleteUserDataModal(false)}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
      {unbanUserModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div className="request" style={{ width: "40vw", cursor: "default", zIndex: 98 }}>
            <h2
              style={{ marginBottom: 10, color: "red", alignSelf: "center", textAlign: "center" }}>
              Are you sure you want to unban this user?
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 20,
                marginBottom: 20,
              }}>
              {actionUser.fax ? (
                <img
                  src={require("../docAvatar.png")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              ) : (
                <img
                  src={
                    actionUser.profilePictur ? actionUser.profilePictur : require("../avatar.png")
                  }
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              )}
              <h2 style={{ color: "#000" }}>{actionUser.name}</h2>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "80%",
                marginTop: 20,
                alignSelf: "center",
              }}>
              <div className="button" onClick={unbanUser}>
                Confirm
              </div>
              <div
                className="button"
                style={{ backgroundColor: "red" }}
                onClick={() => setUnbanUserModal(false)}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
      {analyticsModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "zoom-out",
          }}>
          <div
            ref={wrapperRefTwo}
            className="request"
            style={{
              width: "60vw",
              height: "50vh",
              cursor: "default",
              zIndex: 98,
              padding: 25,
              position: "relative",
              overflow: "hidden"
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 20,
                marginBottom: 20,
              }}>
              {actionUser.fax ? (
                <img
                  src={require("../docAvatar.png")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              ) : (
                <img
                  src={
                    actionUser.profilePictur ? actionUser.profilePictur : require("../avatar.png")
                  }
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                    border: "solid",
                    borderColor: "#2872fa",
                    borderWidth: 1.7,
                  }}
                />
              )}
              <h2 style={{ color: "#000" }}>{actionUser.name}</h2>
            </div>
            <div
              style={{
                width: "100%",
                height: 140,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                paddingTop: 10,
              }}>
              <div className="analyticsSquare">
                <p style={{ position: "absolute", top: 10 }}>
                  <strong>Total Requests</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>
                  {totalRequestsCount + completedRequestsCount + rejectedRequestsCount + 0}
                </p>
              </div>
              <div className="analyticsSquare">
                <p style={{ position: "absolute", top: 10 }}>
                  <strong>Completed Requests</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{completedRequestsCount + 0}</p>
              </div>
              <div className="analyticsSquare">
                <p style={{ position: "absolute", top: 10 }}>
                  <strong>Rejected Requests</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{rejectedRequestsCount + 0}</p>
              </div>
              <div className="analyticsSquare">
                <p style={{ position: "absolute", top: 10 }}>
                  <strong>Today's Requests</strong>
                </p>
                <p style={{ fontSize: 40, fontWeight: "500" }}>{todayRequestsCount + 0}</p>
              </div>
            </div>
            {loading && (
              <div className="splash">
                <Lottie options={defaultOptions} height={100} width={100} />
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer containerId="phid" draggable={false} />
    </div>
  );
}
