import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Background from "../componants/Background";
import { useReactToPrint } from "react-to-print";
import Splash from "../componants/Splash";
import { getFunctions, httpsCallable } from "firebase/functions";
import TransparentLoading from "../componants/TransparentLoading";
import { Link } from "react-router-dom";

export default function Request(props) {
  const [wait, setWait] = useState(false);
  const [phData, setPhData] = useState("");
  const [notes, setNotes] = useState("");
  const [choosePH, setChoosePH] = useState(false);
  const [selectedPH, setSelectedPH] = useState("");
  const [loading, setLoading] = useState(false);
  const [rejectPopup, setRjectPopup] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [success, setSuccess] = useState(false);

  const auth = getAuth();
  const functions = getFunctions();
  const componentRef = useRef();
  const db = getFirestore();
  let today = new Date().toLocaleDateString();

  useEffect(() => {
    setTimeout(() => {
      setWait(true);
    }, 100);
  }, []);

  useEffect(() => {
    async function getPhInfo() {
      console.log(props?.location?.state.data.pEmail);
      const docRef = doc(db, "pharmacies", auth.currentUser.email);
      const docSnap = await getDoc(docRef);
      setPhData(docSnap.data());
    }

    getPhInfo();

    return console.log("");
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `PH Health Connect - Request from ${props?.location?.state?.data?.pName}`,
  });

  const sendNotification = async () => {
    const docRef = doc(db, "patients", props?.location?.state.data.pEmail, "token", "userToken");
    const docSnap = await getDoc(docRef).then((doc) => {
      let token = doc.data()?.deviceToken;

      if (token) {
        const addMessage = httpsCallable(functions, "sendNotificationFromCloud");
        addMessage({ token: token })
          .then(() => {
            console.log("function called");
          })
          .catch((err) => {
            console.log("error is " + err);
          });
      } else return null;
    });
  };

  const Accept = async (request) => {
    setLoading(true);

    const newDocRef = await addDoc(
      collection(db, "pharmacies", auth.currentUser.email, "completed"),
      {
        dateOfProcess: today,
        pName: request.data.pName,
        pEmail: request.data.pEmail,
        pPhone: request.data.pPhone,
        pPhoto: request.data.pPhoto,
        pAddress: request.data.pAddress,
        pCity: request.data.pCity,
        pDateOfBirth: request.data.pDateOfBirth,
        pPostalCode: request.data.pPostalCode,
        timeStamp: request.data.timeStamp,
        form: request.data.form,
        attachedPic: request.data.attachedPic ? request.data.attachedPic : null,
        q1: request.data.q1,
        a1: request.data.a1,
        q2: request.data.q2,
        a2: request.data.a2,
        q3: request.data.q3,
        a3: request.data.a3,
        q4: request.data.q4,
        a4: request.data.a4,
        q5: request.data.q5 ? request.data.q5 : null,
        a5: request.data.a5 ? request.data.a5 : null,
        q6: request.data.q6 ? request.data.q6 : null,
        a6: request.data.a6 ? request.data.a6 : null,
        q7: request.data.q7 ? request.data.q7 : null,
        a7: request.data.a7 ? request.data.a7 : null,
        q8: request.data.q8 ? request.data.q8 : null,
        a8: request.data.a8 ? request.data.a8 : null,
        q9: request.data.q9 ? request.data.q9 : null,
        a9: request.data.a9 ? request.data.a9 : null,
        q10: request.data.q10 ? request.data.q10 : null,
        a10: request.data.a10 ? request.data.a10 : null,
        q11: request.data.q11 ? request.data.q11 : null,
        a11: request.data.a11 ? request.data.a11 : null,
        q12: request.data.q12 ? request.data.q12 : null,
        a12: request.data.a12 ? request.data.a12 : null,
        q13: request.data.q13 ? request.data.q13 : null,
        a13: request.data.a13 ? request.data.a13 : null,
        q14: request.data.q14 ? request.data.q14 : null,
        a14: request.data.a14 ? request.data.a14 : null,
        q15: request.data.q15 ? request.data.q15 : null,
        a15: request.data.a15 ? request.data.a15 : null,
        q16: request.data.q16 ? request.data.q16 : null,
        a16: request.data.a16 ? request.data.a16 : null,
        q17: request.data.q17 ? request.data.q17 : null,
        a17: request.data.a17 ? request.data.a17 : null,
        q18: request.data.q18 ? request.data.q18 : null,
        a18: request.data.a18 ? request.data.a18 : null,
        q19: request.data.q19 ? request.data.q19 : null,
        a19: request.data.a19 ? request.data.a19 : null,
      }
    )
      .then(async () => {
        await sendNotification();
      })
      .then(async () => {
        const newDocRef = await addDoc(collection(db, "patients", request.data.pEmail, "replies"), {
          PH: phData.name,
          address: phData.address,
          lat: phData.lat,
          lng: phData.lng,
          pharmacist: selectedPH ? selectedPH : phData.pharmacist1,
          form: request.data.form,
          status: "accepted",
          response: notes ? notes : "",
          timeStamp: serverTimestamp(),
        });
        await deleteDoc(
          doc(db, "pharmacies", auth.currentUser.email, "requests", request.id)
        ).finally(() => {
          setLoading(false);
          setSuccess(true);
        });
      });
  };

  const Reject = async (request) => {
    setLoading(true);

    await addDoc(collection(db, "pharmacies", auth.currentUser.email, "rejected"), {
      pName: request.data.pName,
      pEmail: request.data.pEmail,
      pPhone: request.data.pPhone,
      pPhoto: request.data.pPhoto,
      pAddress: request.data.pAddress,
      pCity: request.data.pCity,
      pDateOfBirth: request.data.pDateOfBirth,
      pPostalCode: request.data.pPostalCode,
      timeStamp: request.data.timeStamp,
      form: request.data.form,
      attachedPic: request.data.attachedPic ? request.data.attachedPic : null,
      q1: request.data.q1,
      a1: request.data.a1,
      q2: request.data.q2,
      a2: request.data.a2,
      q3: request.data.q3,
      a3: request.data.a3,
      q4: request.data.q4,
      a4: request.data.a4,
      q5: request.data.q5 ? request.data.q5 : null,
      a5: request.data.a5 ? request.data.a5 : null,
      q6: request.data.q6 ? request.data.q6 : null,
      a6: request.data.a6 ? request.data.a6 : null,
      q7: request.data.q7 ? request.data.q7 : null,
      a7: request.data.a7 ? request.data.a7 : null,
      q8: request.data.q8 ? request.data.q8 : null,
      a8: request.data.a8 ? request.data.a8 : null,
      q9: request.data.q9 ? request.data.q9 : null,
      a9: request.data.a9 ? request.data.a9 : null,
      q10: request.data.q10 ? request.data.q10 : null,
      a10: request.data.a10 ? request.data.a10 : null,
      q11: request.data.q11 ? request.data.q11 : null,
      a11: request.data.a11 ? request.data.a11 : null,
      q12: request.data.q12 ? request.data.q12 : null,
      a12: request.data.a12 ? request.data.a12 : null,
      q13: request.data.q13 ? request.data.q13 : null,
      a13: request.data.a13 ? request.data.a13 : null,
      q14: request.data.q14 ? request.data.q14 : null,
      a14: request.data.a14 ? request.data.a14 : null,
      q15: request.data.q15 ? request.data.q15 : null,
      a15: request.data.a15 ? request.data.a15 : null,
      q16: request.data.q16 ? request.data.q16 : null,
      a16: request.data.a16 ? request.data.a16 : null,
      q17: request.data.q17 ? request.data.q17 : null,
      a17: request.data.a17 ? request.data.a17 : null,
      q18: request.data.q18 ? request.data.q18 : null,
      a18: request.data.a18 ? request.data.a18 : null,
      q19: request.data.q19 ? request.data.q19 : null,
      a19: request.data.a19 ? request.data.a19 : null,
    })
      .then(async () => {
        await sendNotification();
      })
      .then(async () => {
        await deleteDoc(doc(db, "pharmacies", auth.currentUser.email, "requests", request.id));
        const newDocRef = await addDoc(collection(db, "patients", request.data.pEmail, "replies"), {
          PH: phData.name,
          form: request.data.form,
          status: "rejected",
          response: feedback ? feedback : "",
          timeStamp: serverTimestamp(),
        });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(true);
      });
  };

  return (
    <div className="contentC">
      <Background />
      {wait && (
        <div className="contentMC" style={{ backgroundColor: "#2872fa", marginBottom: 20 }}>
          <div style={{ width: "100%", padding: 10, marginBottom: 20 }}>
            <h1 style={{ textAlign: "center", color: "#fff" }}>
              Request From:{" "}
              {props?.location?.state?.data?.pName
                ? props?.location?.state?.data?.pName
                : "Undefined"}
            </h1>
          </div>
          <div className="request" style={{ width: "55vw" }} ref={componentRef}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  props?.location?.state?.data?.pPhoto
                    ? props?.location?.state?.data?.pPhoto
                    : require("../avatar.png")
                }
                className="pProfilePic"
                style={{
                  width: 55,
                  height: 55,
                  borderRadius: 50,
                  resize: "cover",
                  marginRight: 10,
                }}
              />
              <div
                style={{
                  height: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                <h3 style={{ color: "#000" }}>
                  {props?.location?.state?.data?.pName
                    ? props?.location?.state?.data?.pName
                    : "Undefined"}
                </h3>
                <p style={{ fontSize: 12, margin: 0, color: "gray" }}>
                  {props?.location?.state?.data?.timeStamp
                    ? moment(props?.location?.state?.data?.timeStamp?.toDate())
                        .locale("en")
                        .fromNow()
                    : "Undefined"}
                </p>
              </div>
            </div>
            <p className="pDetails">
              <strong>Case:</strong>{" "}
              {props?.location?.state?.data?.form
                ? props?.location?.state?.data?.form
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>City:</strong>{" "}
              {props?.location?.state?.data?.pCity
                ? props?.location?.state?.data?.pCity
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>Address:</strong>{" "}
              {props?.location?.state?.data?.pAddress
                ? props?.location?.state?.data?.pAddress
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>Phone Number:</strong>{" "}
              {props?.location?.state?.data?.pPhone
                ? props?.location?.state?.data?.pPhone
                : "Undefined"}
            </p>
            <p className="pDetails">
              <strong>Date Of Birth:</strong>{" "}
              {props?.location?.state?.data?.pDateOfBirth
                ? props?.location?.state?.data?.pDateOfBirth.toDate().toDateString().substring(4)
                : "Undefined"}
            </p>
            <div style={{ width: "90%", alignSelf: "center", marginTop: 20 }}>
              <h1 style={{ textAlign: "center", color: "#2872fa", marginBottom: 20 }}>
                Questionnaire Results
              </h1>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question One:</strong>{" "}
                {props?.location?.state?.data?.q1 ? props?.location?.state?.data?.q1 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a1 ? props?.location?.state?.data?.a1 : "Undefined"}
              </p>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question Two:</strong>{" "}
                {props?.location?.state?.data?.q2 ? props?.location?.state?.data?.q2 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a2 ? props?.location?.state?.data?.a2 : "Undefined"}
              </p>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question Three:</strong>{" "}
                {props?.location?.state?.data?.q3 ? props?.location?.state?.data?.q3 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a3 ? props?.location?.state?.data?.a3 : "Undefined"}
              </p>
              <p className="pDetails">
                <strong style={{ color: "red" }}>Question Four:</strong>{" "}
                {props?.location?.state?.data?.q4 ? props?.location?.state?.data?.q4 : "Undefined"}
              </p>
              <p className="pDetails" style={{ marginBottom: 20 }}>
                <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                {props?.location?.state?.data?.a4 ? props?.location?.state?.data?.a4 : "Undefined"}
              </p>
              {props?.location?.state?.data?.q5 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Five:</strong>{" "}
                    {props?.location?.state?.data?.q5
                      ? props?.location?.state?.data?.q5
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a5
                      ? props?.location?.state?.data?.a5
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q6 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Six:</strong>{" "}
                    {props?.location?.state?.data?.q6
                      ? props?.location?.state?.data?.q6
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a6
                      ? props?.location?.state?.data?.a6
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q7 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Seven:</strong>{" "}
                    {props?.location?.state?.data?.q7
                      ? props?.location?.state?.data?.q7
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a7
                      ? props?.location?.state?.data?.a7
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q8 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Eight:</strong>{" "}
                    {props?.location?.state?.data?.q8
                      ? props?.location?.state?.data?.q8
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a8
                      ? props?.location?.state?.data?.a8
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q9 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Nine:</strong>{" "}
                    {props?.location?.state?.data?.q9
                      ? props?.location?.state?.data?.q9
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a9
                      ? props?.location?.state?.data?.a9
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q10 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Ten:</strong>{" "}
                    {props?.location?.state?.data?.q10
                      ? props?.location?.state?.data?.q10
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a10
                      ? props?.location?.state?.data?.a10
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q11 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Eleven:</strong>{" "}
                    {props?.location?.state?.data?.q11
                      ? props?.location?.state?.data?.q11
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a11
                      ? props?.location?.state?.data?.a11
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q12 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Twelve:</strong>{" "}
                    {props?.location?.state?.data?.q12
                      ? props?.location?.state?.data?.q12
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a12
                      ? props?.location?.state?.data?.a12
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q13 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Thirteen:</strong>{" "}
                    {props?.location?.state?.data?.q13
                      ? props?.location?.state?.data?.q13
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a13
                      ? props?.location?.state?.data?.a13
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q14 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Fourteen:</strong>{" "}
                    {props?.location?.state?.data?.q14
                      ? props?.location?.state?.data?.q14
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a14
                      ? props?.location?.state?.data?.a14
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q15 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Fifteen:</strong>{" "}
                    {props?.location?.state?.data?.q15
                      ? props?.location?.state?.data?.q15
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a15
                      ? props?.location?.state?.data?.a15
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q16 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Sixteen:</strong>{" "}
                    {props?.location?.state?.data?.q16
                      ? props?.location?.state?.data?.q16
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a16
                      ? props?.location?.state?.data?.a16
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q17 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Seventeen:</strong>{" "}
                    {props?.location?.state?.data?.q17
                      ? props?.location?.state?.data?.q17
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a17
                      ? props?.location?.state?.data?.a17
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.q18 && (
                <>
                  <p className="pDetails">
                    <strong style={{ color: "red" }}>Question Eighteen:</strong>{" "}
                    {props?.location?.state?.data?.q18
                      ? props?.location?.state?.data?.q18
                      : "Undefined"}
                  </p>
                  <p className="pDetails" style={{ marginBottom: 20 }}>
                    <strong style={{ color: "#00c93c" }}>Answer:</strong>{" "}
                    {props?.location?.state?.data?.a18
                      ? props?.location?.state?.data?.a18
                      : "Undefined"}
                  </p>
                </>
              )}
              {props?.location?.state?.data?.attachedPic && (
                <div>
                  <p className="pDetails" style={{ marginBottom: 10 }}>
                    <strong style={{ color: "red" }}>Attched Picture:</strong>
                  </p>
                  <img
                    src={props?.location?.state?.data?.attachedPic}
                    style={{ width: 300, height: 250, objectFit: "cover", borderRadius: 10 }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="request" style={{ width: "55vw" }}>
            <h1 style={{ marginBottom: 20, color: "#2872fa", alignSelf: "center" }}>
              Request Processor
            </h1>
            <p className="pDetails" style={{ marginBottom: 20, fontSize: 17, marginBottom: 1 }}>
              <strong style={{ color: "#2872fa" }}>Pharmacy:</strong> {phData.name}
            </p>
            <p className="pDetails" style={{ marginBottom: 20, fontSize: 17, marginBottom: 1 }}>
              <strong style={{ color: "#2872fa" }}>Pharmacy Address:</strong> {phData.address}
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="pDetails" style={{ marginBottom: 20, fontSize: 17, marginBottom: 1 }}>
                <strong style={{ color: "#2872fa" }}>Pharmacist Name:</strong>{" "}
                {selectedPH ? selectedPH : phData.pharmacist1}
              </p>
              <img
                onClick={() => setChoosePH(true)}
                src={require("../edit.png")}
                style={{
                  width: 20,
                  height: 20,
                  objectFit: "cover",
                  marginLeft: 4,
                  marginTop: 4,
                  cursor: "pointer",
                }}
              />
            </div>
            <p className="pDetails" style={{ marginBottom: 20, fontSize: 17, marginBottom: 1 }}>
              <strong style={{ color: "#2872fa" }}>Send Additional Notes To Patient:</strong>
            </p>
            <textarea
              value={notes}
              onChange={(t) => setNotes(t.target.value)}
              placeholder="Additional Notes (Optional)"
              type="text"
              style={{
                width: "89%",
                height: 120,
                alignSelf: "center",
                marginTop: 15,
                borderRadius: 15,
                borderColor: "#2872fa",
                borderWidth: 2,
                padding: 10,
                fontSize: 16,
                maxWidth: "89%",
                minWidth: "89%",
                minHeight: 60,
              }}
            />
            <div
              onClick={() => Accept(props?.location?.state)}
              className="accBTN"
              style={{
                width: "80%",
                height: 45,
                display: "flex",
                borderRadius: 25,
                overflow: "hidden",
                marginTop: 20,
                marginBottom: 0,
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}>
              <p>
                <strong>Accept and Send to Patient</strong>
              </p>
            </div>
          </div>
          <div
            style={{
              width: "50vw",
              height: 45,
              display: "flex",
              borderRadius: 25,
              overflow: "hidden",
              marginTop: 20,
              marginBottom: 0,
              alignSelf: "center",
            }}>
            <div
              onClick={() => setRjectPopup(true)}
              className="rejBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}>
              <p>
                <strong>Reject This Request</strong>
              </p>
            </div>
            <div
              onClick={handlePrint}
              className="vmdBTN"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}>
              <p style={{ color: "#fff" }}>
                <strong>Export As PDF</strong>
              </p>
              <img
                src={require("../export-pdf.png")}
                style={{ width: 33, height: 33, objectFit: "cover", marginLeft: 5 }}
              />
            </div>
          </div>
        </div>
      )}
      {choosePH && (
        <div
          onClick={() => setChoosePH(false)}
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "zoom-out",
          }}>
          <div className="request" style={{ width: "40vw", cursor: "default" }}>
            <h1 style={{ marginBottom: 20, color: "#2872fa", alignSelf: "center" }}>
              Change Pharmacist
            </h1>
            <div
              className="PHOption"
              onClick={() => {
                setSelectedPH(phData.pharmacist1);
                setChoosePH(false);
              }}>
              <h4 style={{ color: "#444", fontSize: 18 }}>{phData.pharmacist1}</h4>
            </div>
            {phData.pharmacist2 && (
              <div
                className="PHOption"
                onClick={() => {
                  setSelectedPH(phData.pharmacist2);
                  setChoosePH(false);
                }}>
                <h4 style={{ color: "#444", fontSize: 18 }}>{phData.pharmacist2}</h4>
              </div>
            )}
            {phData.pharmacist3 && (
              <div
                className="PHOption"
                onClick={() => {
                  setSelectedPH(phData.pharmacist3);
                  setChoosePH(false);
                }}>
                <h4 style={{ color: "#444", fontSize: 18 }}>{phData.pharmacist3}</h4>
              </div>
            )}
          </div>
        </div>
      )}
      {rejectPopup && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div className="request" style={{ width: "40vw", cursor: "default", zIndex: 98 }}>
            <h2 style={{ marginBottom: 10, color: "red", alignSelf: "center" }}>
              Are you sure you want to reject this request?
            </h2>
            <textarea
              value={feedback}
              onChange={(t) => setFeedback(t.target.value)}
              placeholder="Send feedback to Patient (Optional)"
              type="text"
              style={{
                width: "89%",
                height: 120,
                alignSelf: "center",
                marginTop: 15,
                borderRadius: 15,
                borderColor: "#2872fa",
                borderWidth: 2,
                padding: 10,
                fontSize: 16,
                maxWidth: "89%",
                minWidth: "89%",
                minHeight: 60,
                maxHeight: "40vh",
                zIndex: 99,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "80%",
                marginTop: 20,
                alignSelf: "center",
              }}>
              <div className="button" onClick={() => Reject(props?.location?.state)}>
                Confirm
              </div>
              <div
                className="button"
                style={{ backgroundColor: "red" }}
                onClick={() => setRjectPopup(false)}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
      <h3 style={{ fontSize: 1 }}>.</h3>
      {loading && <TransparentLoading />}
      {success && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#11111170",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            className="request"
            style={{
              width: "50vw",
              cursor: "default",
              zIndex: 98,
              height: "52vh",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#2872fa",
              borderWidth: 0,
              border: 0,
            }}>
            <h2 style={{ marginBottom: 10, color: "#fff", alignSelf: "center" }}>
              The request has been processed successfully
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "80%",
                marginTop: 20,
                alignSelf: "center",
              }}>
              <Link
                className="button"
                to="/home"
                style={{ backgroundColor: "#fff", textDecoration: "none" }}>
                <h4 style={{ color: "#2872fa" }}>Return to homepage</h4>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
