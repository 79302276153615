import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import Background from "../componants/Background";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
} from "react-instantsearch-hooks-web";

//algolia credentials
const searchClient = algoliasearch("EW0U9EKMBK", "e3297bbe9d287066fcc63f4c0ec83382");

function Hit({ hit }) {
  return (
    <div>
      <div className="patientListItem">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={hit.profilePictur ? hit.profilePictur : require("../avatar.png")}
            style={{
              width: 55,
              height: 55,
              borderRadius: 50,
              resize: "cover",
              marginRight: 10,
              border: "solid",
              borderColor: "#2872fa",
              borderWidth: 1.7,
            }}
          />
          <h3 style={{ color: "#000" }}>{hit.name}</h3>
        </div>
        <div style={{ color: "#444", paddingLeft: 10, marginTop: 10 }}>
          <div className="patientDetails">
            <h4>Date Of Birth:&nbsp;&nbsp;</h4>
            <p>{"22/04/1887"}</p>
          </div>
          <div className="patientDetails">
            <h4>City:&nbsp;&nbsp;</h4>
            <p>{hit.city}</p>
          </div>
          <div className="patientDetails">
            <h4>Phone Number:&nbsp;&nbsp;</h4>
            <p>{hit.phoneNumber}</p>
          </div>
          <div className="patientDetails">
            <h4>Postal Code:&nbsp;&nbsp;</h4>
            <p>{hit.postalCode}</p>
          </div>
          <div className={"patientDetails addressD"}>
            <h4>Address:&nbsp;&nbsp;</h4>
            <p>{hit.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Patients() {
  const [patients, setPatients] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    async function getPatientsData() {
      const pRef = collection(db, "patients");
      const docsSnap = await getDocs(pRef);
      setPatients(
        docsSnap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    }

    getPatientsData();
  }, []);

  return (
    <div className="contentC">
      <div style={{ marginTop: 100 }}>
        <InstantSearch searchClient={searchClient} indexName="patients">
          <SearchBox
            autoFocus
            placeholder="Search Users..."
            className="searchbox"
            style={{ marginBottom: 10, borderRadius: 50, overflow: "hidden" }}
          />

          <Hits hitComponent={Hit} />
        </InstantSearch>
      </div>
      <Background />
    </div>
  );
}
