import React, { useState } from "react";
import Background from "../componants/Background";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const auth = getAuth();

  const resetPassword = async () => {
    if (email) {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          setEmail("");
          toast.success("The password reset link has been sent successfully");
        })
        .catch((e) => {
           switch (e.code) {
             case "auth/invalid-email":
               toast.error("Please enter a correct email address");
               break;
             case "auth/user-not-found":
               toast.error("No account was found linked to this email address");
               break;
           }
        });
    } else {
      toast.error("Please add your email address");
    }
  };

  return (
    <div className="contentC">
      <div className="contentMC">
        <h2 className="blue">Reset Password</h2>
        <p style={{ color: "#555", marginTop: 10 }}>
          Kindly add your email address below to reset your password
        </p>

        <input
          value={email}
          onChange={(t) => setEmail(t.target.value)}
          placeholder="Email Address"
          style={{
            width: "55%",
            height: 45,
            alignSelf: "center",
            marginTop: 15,
            borderRadius: 25,
            borderColor: "#2872fa",
            borderWidth: 2,
            padding: 10,
            fontSize: 16,
          }}
        />

        <div className="button" style={{ marginTop: 20 }} onClick={resetPassword}>
          Submit
        </div>
      </div>
      <Background />
      <h3 style={{ fontSize: 40, opacity: 0 }}>.</h3>
      <ToastContainer containerId="phid" draggable={false} />
    </div>
  );
}

export default ForgotPassword;
