import React, { useEffect, useRef, useState } from "react";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Lottie from "react-lottie";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Splash from "./Splash";

export default function CompleteProfile() {
  const [showAddress, setShowAddress] = useState(false);
  const [selected, setSelected] = useState(null);
  const [buttonState, setButtonState] = useState("Start");
  const [err, setErr] = useState(null);
  const [err2, setErr2] = useState(null);
  const [showPH, setShowPH] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [pharmacists, setPharmacists] = useState({
    pharmacist1: "",
    pharmacist1N: "",
    pharmacist2: "",
    pharmacist2N: "",
    pharmacist3: "",
    pharmacist3N: "",
  });

  const auth = getAuth();
  const db = getFirestore();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../homeDoc.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: require("../success.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: {
        country: "CA",
      },
    },
    debounce: 300,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAFmcso6P6HlenAyJGLm-lh5-scElN3gX8",
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setSelected({ lat, lng });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div className="addressOption" key={place_id} onClick={handleSelect(suggestion)}>
          <p>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </p>
        </div>
      );
    });

  const setUpProfile = () => {
    if (showAddress == false && selected == null) {
      setShowAddress(true);
      setButtonState("Next");
    } else if (selected == null) {
      setErr("You must set your address before you continue");
      setTimeout(() => setErr(null), 3000);
    } else if (selected && buttonState == "Next") {
      setShowAddress(false);
      setButtonState("Submit");
      setTimeout(() => setShowPH(true), 1000);
    } else if (pharmacists.pharmacist1 == "") {
      setErr2("You must add at least one Parmacist to your account");
      setTimeout(() => setErr2(null), 3000);
    } else if (pharmacists.pharmacist1 !== "" && pharmacists.pharmacist1N == "") {
      setErr2("Please add the Registration Number");
      setTimeout(() => setErr2(null), 3000);
    } else if (pharmacists.pharmacist2 !== "" && pharmacists.pharmacist2N == "") {
      setErr2("Please add the Registration Number");
      setTimeout(() => setErr2(null), 3000);
    } else if (pharmacists.pharmacist3 !== "" && pharmacists.pharmacist3N == "") {
      setErr2("Please add the Registration Number");
      setTimeout(() => setErr2(null), 3000);
    } else {
      setLoading(true);
      async function uploadData() {
        const userDoc = doc(db, "pharmacies", auth.currentUser.email);

        await updateDoc(userDoc, {
          address: value,
          lat: selected.lat,
          lng: selected.lng,
          pharmacist1: pharmacists.pharmacist1,
          pharmacist1N: pharmacists.pharmacist1N,
          pharmacist2: pharmacists.pharmacist2 ? pharmacists.pharmacist2 : null,
          pharmacist2N: pharmacists.pharmacist2N ? pharmacists.pharmacist2N : null,
          pharmacist3: pharmacists.pharmacist3 ? pharmacists.pharmacist3 : null,
          pharmacist3N: pharmacists.pharmacist3N ? pharmacists.pharmacist3N : null,
          accountStatus: "pending"
        }).then(() => {
          setDone(true);
          setTimeout(() => setLoading(false), 900);
        });
      }

      uploadData();
    }
  };

  return (
    <div className="infoC">
      {done ? (
        <div className="infoSection">
          <h2 style={{ color: "#fff" }}>Your Profile has been set up successfully</h2>
          <Lottie options={defaultOptions2} height={200} width={200} style={{ marginTop: 40 }} />
          <div
            className="button"
            style={{ backgroundColor: "#fff", borderRadius: 50, marginTop: 40 }}
            onClick={() => window.location.reload()}>
            <h4 style={{ color: "#2872fa", fontSize: 18 }}>Refresh Page</h4>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="infoSection">
            <h2 style={{ color: "#fff" }}>Hello, Lets Set Up Your Profile!</h2>
            <Lottie options={defaultOptions} height={300} width={200} />
            <div
              className="button"
              style={{ backgroundColor: "#fff", borderRadius: 50 }}
              onClick={setUpProfile}>
              <h4 style={{ color: "#2872fa", fontSize: 18 }}>{buttonState}</h4>
            </div>
          </div>
          <div className={`infoSection2 ${showAddress ? "show" : ""}`}>
            <>
              <h4
                style={{
                  color: "#fff",
                  fontSize: 19,
                  marginTop: value && -280,
                  opacity: value && 0,
                  transition: "0.5s",
                  marginBottom: 3,
                }}>
                Please add your Address
              </h4>
              <h5 className="errText">{err}</h5>
              <div ref={ref}>
                <input
                  style={{
                    marginTop: value && -280,
                    transition: "0.5s",
                  }}
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  placeholder="Your Address"
                  className="mapInput"
                />
                {/* We can use the "status" to decide whether we should display the dropdown or not */}
                {status === "OK" && <ul className="addressOptionsC">{renderSuggestions()}</ul>}
              </div>
              {selected && showAddress && value.length > 5 && (
                <>
                  {isLoaded && (
                    <GoogleMap
                      streetView={true}
                      zoom={15}
                      center={selected}
                      mapContainerClassName="mapC">
                      {selected && <Marker position={selected} />}
                    </GoogleMap>
                  )}
                </>
              )}
            </>
          </div>
          <div className={`infoSection2 ${showPH ? "show" : ""}`}>
            <h4
              style={{
                color: "#fff",
                fontSize: 19,
                transition: "0.7s",
                marginBottom: 20,
              }}>
              Please add the names of your Pharmacists
            </h4>
            <h5 className="errText" style={{ marginTop: 90 }}>
              {err2}
            </h5>
            <div style={{ display: "flex" }}>
              <input
                className="PHInput"
                placeholder="Pharmacist 1"
                value={pharmacists.pharmacist1}
                onChange={(e) => setPharmacists({ ...pharmacists, pharmacist1: e.target.value })}
                style={{ borderBottomColor: pharmacists.pharmacist1 && "#0BDA51" }}
              />
              <input
                className="PHInput"
                placeholder="Registration Number"
                value={pharmacists.pharmacist1N}
                onChange={(e) => setPharmacists({ ...pharmacists, pharmacist1N: e.target.value })}
                style={{ borderBottomColor: pharmacists.pharmacist1N && "#0BDA51" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <input
                className="PHInput"
                placeholder="Pharmacist 2"
                value={pharmacists.pharmacist2}
                onChange={(e) => setPharmacists({ ...pharmacists, pharmacist2: e.target.value })}
                style={{ borderBottomColor: pharmacists.pharmacist2 && "#0BDA51" }}
              />
              <input
                className="PHInput"
                placeholder="Registration Number"
                value={pharmacists.pharmacist2N}
                onChange={(e) => setPharmacists({ ...pharmacists, pharmacist2N: e.target.value })}
                style={{ borderBottomColor: pharmacists.pharmacist2N && "#0BDA51" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <input
                className="PHInput"
                placeholder="Pharmacist 3"
                value={pharmacists.pharmacist3}
                onChange={(e) => setPharmacists({ ...pharmacists, pharmacist3: e.target.value })}
                style={{ borderBottomColor: pharmacists.pharmacist3 && "#0BDA51" }}
              />
              <input
                className="PHInput"
                placeholder="Registration Number"
                value={pharmacists.pharmacist3N}
                onChange={(e) => setPharmacists({ ...pharmacists, pharmacist3N: e.target.value })}
                style={{ borderBottomColor: pharmacists.pharmacist3N && "#0BDA51" }}
              />
            </div>
          </div>
        </>
      )}
      {loading && <Splash />}
    </div>
  );
}
