import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  const [accountStatus, setaccountStatus] = useState(null);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    async function getData() {
      const docRef = doc(db, "pharmacies", auth.currentUser.email);
      const docSnap = await getDoc(docRef);
      setaccountStatus(docSnap.data().accountStatus);
    }

    getData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: 75,
        backgroundColor: "#2872fa",
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 30,
        zIndex: 111,
      }}>
      <img src={require("../hcLogo.png")} style={{ width: 90, height: 90 }} alt="logo" />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Link to="/home" className="navLink">
          Home
        </Link>
        <Link to="/Guidelines" className="navLink">
          Guidelines
        </Link>
        {accountStatus == "active" && (
          <Link to="/Patients" className="navLink">
            Patients
          </Link>
        )}
        <Link to="/Medications" className="navLink">
          Medications
        </Link>
        <Link to="/PrivacyPolicy" className="navLink">
          Privacy Policy
        </Link>
        <Link to="/Profile" className="navPicBTN">
          <img
            src={require("../docAvatar.png")}
            style={{
              width: 40,
              height: 40,
              resizeMode: "cover",
              borderRadius: 50,
              marginLeft: 20,
            }}
          />
        </Link>
        {auth.currentUser.email == "markmhr96@gmail.com" && (
          <Link to="/Admin" className="navPicBTN">
            <img
              src={require("../adminNav2.png")}
              style={{
                width: 40,
                height: 40,
                resizeMode: "cover",
                borderRadius: 50,
                marginLeft: 12,
              }}
            />
          </Link>
        )}
        {auth.currentUser.email == "mouayad.alkallas@gmail.com" && (
          <Link to="/Admin" className="navPicBTN">
            <img
              src={require("../adminNav2.png")}
              style={{
                width: 40,
                height: 40,
                resizeMode: "cover",
                borderRadius: 50,
                marginLeft: 12,
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
}
