import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, NavLink, Redirect } from "react-router-dom";
import SignUpForm from "./pages/SignUpForm";
import SignInForm from "./pages/SignInForm";
import { useMediaQuery } from "react-responsive";
import { firebaseConfig } from "./firebase";
import { getApps, initializeApp } from "firebase/app";
import "./App.css";
import Splash from "./componants/Splash";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Home from "./pages/Home";
import Guidelines from "./pages/Guidelines";
import Patients from "./pages/Patients";
import Medications from "./pages/Medications";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import VerifyEmail from "./componants/VerifyEmail";
import Nav from "./componants/Nav";
import Request from "./pages/Request";
import MobileView from "./componants/MobileView";
import Completed from "./pages/Completed";
import Rejected from "./pages/Rejected";
import CompletedRequest from "./pages/CompletedRequest";
import Admin from "./pages/Admin";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import Banned from "./componants/Banned";
import RejectedUser from "./componants/RejectedUser";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordForm from "./pages/ForgotPasswordForm";

// Initialize Firebase
if (getApps().length < 1) {
  initializeApp(firebaseConfig);
}

function App() {
  const [loading, setLoading] = useState(true);

  const auth = getAuth();
  const db = getFirestore();
  const [user, setUser] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      const docRef = user ? doc(db, "pharmacies", user.email) : null;
      const docSnap = user ? await getDoc(docRef) : null;
      if (user?.emailVerified) {
        setUser("Ph");
        setTimeout(() => {
          setLoading(false);
        }, 900);
      } else if (user) {
        setUser("user");
        setTimeout(() => {
          setLoading(false);
        }, 900);
      } else {
        // User is signed out
        setUser(null);
        setTimeout(() => {
          setLoading(false);
        }, 900);
      }

      if (docSnap?.data()?.accountStatus == "banned") {
        setUser("banned");
      }
      if (docSnap?.data()?.accountStatus == "rejected") {
        setUser("rejected");
      }
    });
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 555px)",
  });

  return (
    <Router basename="/">
      <div className="App">
        {loading && <Splash />}
        {isMobile && <MobileView />}
        {user == "user" && (
          <>
            <VerifyEmail />
          </>
        )}
        {user == "banned" && (
          <>
            <Banned />
          </>
        )}
        {user == "rejected" && (
          <>
            <RejectedUser />
          </>
        )}
        {user == "Ph" && (
          <>
            <Nav />
            <Redirect exact from="/" to="/Home" />
            <Route path="/Home" component={Home} />
            <Route path="/Guidelines" component={Guidelines} />
            <Route path="/Patients" component={Patients} />
            <Route path="/Medications" component={Medications} />
            <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
            <Route path="/Profile" component={Profile} />
            <Route path="/Request" component={Request} />
            <Route path="/Completed" component={Completed} />
            <Route path="/Rejected" component={Rejected} />
            <Route path="/-Request" component={CompletedRequest} />
            <Route path="/Admin" component={Admin} />
            <Route path="/ForgotPassword" component={ForgotPassword} />
          </>
        )}
        {!user && (
          <>
            <Redirect exact from="/" to="/sign-in" />
            {isDesktopOrLaptop && (
              <div className="appAside">
                <img src={require("./hcLogo.png")} style={{ width: 300, height: 300 }} />
                <h1 style={{ marginTop: -20, fontSize: 50, marginBottom: 70, color: "#fff" }}>
                  Health Connect
                </h1>
              </div>
            )}
            <div className="appForm">
              <div className="pageSwitcher">
                <NavLink
                  to="/sign-in"
                  activeClassName="pageSwitcherItem-active"
                  className="pageSwitcherItem">
                  Sign In
                </NavLink>
                <NavLink
                  exact
                  to="/"
                  activeClassName="pageSwitcherItem-active"
                  className="pageSwitcherItem">
                  Sign Up
                </NavLink>
              </div>

              <div className="formTitle">
                <NavLink
                  to="/sign-in"
                  activeClassName="formTitleLink-active"
                  className="formTitleLink">
                  Sign In
                </NavLink>{" "}
                or{" "}
                <NavLink
                  exact
                  to="/"
                  activeClassName="formTitleLink-active"
                  className="formTitleLink">
                  Sign Up
                </NavLink>
              </div>

              <Route exact path="/" component={SignUpForm} />
              <Route path="/sign-in" component={SignInForm} />
              <Route path="/forgot-password" component={ForgotPasswordForm} />
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
