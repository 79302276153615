import React from "react";
import Background from "../componants/Background";

export default function Guidelines() {
  return (
    <div className="contentC">
      <Background />
    </div>
  );
}
