import React from "react";

export default function Background() {
  return (
    <img
      src={require("../bg.jpg")}
      className="bgPicture"
    />
  );
}
