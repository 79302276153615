import React from "react";
import Background from "../componants/Background";

export default function PrivacyPolicy() {
  const policyText = `
Last Revision: April 2023
INTRODUCTION AND DEFINITIONS

This privacy policy (the “Privacy Policy”) constitutes an agreement between you, or if you are under the age of majority in your local jurisdiction, your parent or legal guardian (“you” or “your”) and the Health Service Provider (i.e. pharmacy/clinic) licensing the "Health Connect" name and software for your local jurisdiction (as indicated in our Terms of Use) and its affiliates and subsidiaries (collectively, “Health Connect”, “us”, “our” or “we”).

Health connect is dedicated to protecting your privacy rights. This Privacy Policy describes how Health Connect collects, protects, uses and discloses personal information and data created in the course of your access to and use of the Site, Content and/or Services (each as defined below).

Please note that this Privacy Policy distinguishes between information collected through the Site, Content and/or Services from you as a client of Health connect and information you provide in the course of receiving a Health Service from a Health Service Provider as a patient of the Health Service Provider (please see next paragraph).

When receiving a Health Service from a Health Service Provider as a patient of the Health Service Provider your privacy is the responsibility of that Health Service Provider. When Health Connect assists your Health Service Provider in providing a Health Service through Health Connect’s platform and services, Health Connect is acting as an affiliate of your Health Service Provider. In this role, Health Connect is responsible for following the privacy policies of your Health Service Provider and protecting your personal and health information in accordance with the relevant legislation that your Health Service Provider is subject to.

If you have any questions or concerns after reading this, please email us at: info@phhealthconnect.com.

For the purposes of this Privacy Policy:

"Content" means any expression fixed in a tangible medium and includes, without limitation, ideas, text, comments, video, audio, images, graphics, designs, drawings, animations, logos, trademarks, copyrights, information, data, software, scripts, tasks, activities, badges and ranks, and any intellectual property therein, any of which may be created, submitted, or otherwise made accessible on or through the Site and/or Services.

“Health Service” means any health care related service (as defined by relevant legislation) that is provided to a patient by a Health Service Provider, irrespective of whether that service is delivered through the Health Connect platform and services or by other means.

“Health Service Provider” means any provider of Health Services such as pharmacies and clinics contracting to make use of Health Connect to deliver that Health Services to patients, irrespective of whether the Health Service is provided directly or through our platform and services.

"Minor" means a person under the age of majority in the jurisdiction where the dispensing pharmacy is located.

"Patient Representative" means a person who is authorized to act on the patient's behalf to manage the patient's prescriptions and Services.

“Services” means all services, except Health Services, made available by or through Health Connect, including but not limited to services accessed through Health Connect or the Site. “Site”means phhealthconnect.com, its related webpages and sites (including, without limitation, any mobile optimization website and white-labeled/DNS-forwarded sites), and Health Connect’s mobile applications.

"User Generated Content" or "UGC" means any Content whatsoever that  you submit, create, upload, transfer, or otherwise makes available by access to the Site or through the Services, including but not limited to messages, information, images, data or in-media screen shots, video, audio or other Content  posted in any public or private area within the Site or through the Services.

ACCEPTANCE OF TERMS AND REVISIONS

By accessing the Site, submitting information to us (regardless of whether you register an account with us) or downloading, installing or using any of the Content or Services, you consent to becoming a client of Health Connect and consent to the collection and use of your information by Health Connect in accordance with this Privacy Policy.  If you do not accept this Privacy Policy you must not submit information to or register an account with us, access the Site or download, install or use any of the Content or Services.

When you engage a Health Service Provider for a Health Service though the Health Connect platform and services, you are consenting to the Health Service Provider’s services and thus to their privacy policies that Health Connect as their affiliate is also bound to follow in accordance with applicable legislation and agreements between Health Connect and the Health Service Provider. When it comes to delivery of a Health Service and any information involved in same, our Privacy Policy is subordinate to and supports the Health Service Provider’s privacy policies.

We reserve the right to revise this Privacy Policy at any time. We will give you notice of such revisions by posting the revised Privacy Policy at www.phhealthconnect.com/privacy-policy. It is your responsibility to ensure that you are aware of the current Privacy Policy when you access or use the Site and/or Services. If you continue to access or use any of the Site and/or Services after any revisions to this Privacy Policy are posted you will be deemed to have accepted those revisions.

TYPES OF INFORMATION WE COLLECT

There are three types of information we may collect through your access to and use of the Site, Content and/or Services:    

(a) “Personal Information” means information about an identifiable individual, including any “Personal Information” as such term is defined in the Personal Information Protection and Electronic Documents Act (Canada), where Personal Information crosses provincial or international boundaries, or as may be defined in any applicable and any applicable provincial privacy laws, such as the Personal Information Protection Act (British Columbia), the Personal Information Protection Act (Alberta) , and the Act Respecting the Protection of Personal Information in the Privacy Sector (Quebec).

Personal Information may include, but is not limited to, a person’s name, email address, residential address, geolocation information (i.e., your specific location), telephone number, date of birth, gender, occupation, employment status, and in some cases, user name.

Please note that this list is provided as an example only for purposes of the definition. It is not meant to be used as an exhaustive list of actual information collected or held by Health Connect.

(b) “Personal Health Information” means information that may be collected when you engage with a Health Service Provider for a Health Service as such term is defined inapplicable legislation such as the Personal Health Information Protection Act (Ontario), the Health Information Act (Alberta), the Personal Health Information Privacy and Access Act (New Brunswick), the Personal Health Information Act (Newfoundland and Labrador), or the Personal Health Information Act (Nova Scotia).

Personal Health Information may include demographics information such as identity, contact, and personal statistics information (similar to the examples listed under the definition for Personal Information). Additionally, your Health Service Provider may also require you to provide certain physical and mental health information, including but not limited to personal and family medical history information, details of your existing medications, existence of drug allergies, medications requested and prescription information, the name of your primary physician and his or her contact information, your provincial health number and any private health benefits number or account information as part of delivering that Health Service.

Any such collection of Personal Health Information in the course of delivering a Health Service is the responsibility of the Health Service Provider who is providing the Health Service to you. Any questions on Personal Health Information collected should be directed to your Health Service Provider directly. Alternatively, you may submit it through our Privacy Officer using the contact information in this Privacy Policy, and we will take all reasonable steps to forward it to your Health Service Provider on your behalf as required of us by our agreements with them and applicable legislation.

Please note that this list is provided as an example only for purposes of the definition. It is not meant to be used as an exhaustive list of actual information collected or held by your Health Service Provider or by Health Connect as their affiliate.

(c) “Non-Personal Information” means information that does not identify you.  One common source of Non-Personal Information is to de-identify (remove anything that can point to identity) from Personal Information such as, for example, date of birth, age, gender, postal code and non-precise geolocation information (e.g., your city). This information by policy and legislation must be de-identified where necessary by the Health Connect system before it falls under this definition. Non-Personal Information can also include anonymous “Usage Data” which is data that is non-identifying right from the start. Such anonymous data is often associated with your computer, mobile device, and/or media system platform, and may include such information as in-media time, activities, purchases, badges and ranks. Again, should any of this information actually be identified at any time, it must by policy be de-identified wherever necessary by the Health Connect before it falls under this definition.

Personal Health Information may only be de-identified for use as Non-Personal Information when permitted by and at the direction of the Health Service Provider responsible for that information. Any such action will only be done by Health Connect in accordance with our agreements with and in compliance with the policies of the Health Service Provider and applicable legislation they are subject to.

Health Connect only makes use of this Non-Personal Information for internal resource management and planning purposes if and as permitted by agreements and applicable legislation.  None of this information is collected for the purpose of selling to third parties and will only be shared with third parties where those parties may be engaged by Health Connect in support of these purposes.

(d) The choice to provide us with Personal Information and Non-Personal Information (notwithstanding Usage Data) is yours. If you do not wish to have Health Connect collect your Personal Information through the use of our Site, Content and/or Services, you can choose not to provide it. However, your decision to withhold particular details may limit the Services we are able to provide and make it more difficult for us to advise you or suggest appropriate alternatives to our Services. If we are unable to accommodate your requests based on the information that you have provided, we may ask for additional information so that we may better accommodate your needs. At all times though it remains your decision whether to provide or withhold this additional information requested.

The choice to provide your Health Service Provider or Health Connect as their affiliate with Personal Health Information is yours.  If you do not wish to allow your Health Service Provider or Health Connect as their affiliate to collect your Personal Health Information as part of providing you with a Health Service, you can choose not to provide it. However, your decision to withhold particular details may limit the Health Service your Health Service Provider and/or Health Connect as their affiliate are able to provide and make it more difficult to advise you or suggest appropriate alternatives to the Health Service(s) requested.  If your Health Service Provider and/or Health Connect are unable to accommodate your requests based on the information that you have provided, your Health Service Provider and/or Health Connect on their behalf may ask for additional information so that we may better accommodate your needs. At all times though it remains your decision whether to provide or withhold this additional information requested.

APPLICATION OF THIS PRIVACY POLICY

This Privacy Policy only applies to the information that Health Connect collects and uses, or that is supplied by clients through the access to and use of the Site and/or Services. Health Connect is not responsible for the actions of any third parties, the content of their websites, the use of information you provide to them or any products or services they may offer. Any information you disclose to a third party is dealt with in accordance with the privacy policies adopted by each of those third parties. Individuals are encouraged to review the privacy policies maintained by each of those third parties to understand how their information will be used by those entities to process requests. For example, we partner with third party service providers and vendors to provide access to existing payment gateways and your credit card, banking and other billing information is kept with our payment processor and you can read about their privacy policy.
The Site, Content and/or Services may contain links to third party websites (the “Linked Sites”) that are not controlled or operated by Health Connect. Linked Sites are offered solely as a convenience to you and do not constitute our sponsorship or endorsement of, or affiliation with those third parties. The Linked Sites are maintained by third parties who have their own privacy policies and their own terms and conditions of use. This Privacy Policy does not apply to any of the Linked Sites and Health Connect is not responsible for any information disclosed to or collected by those Linked Sites or for the privacy practices of any operators or sponsors of the Linked Sites. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party. Because we cannot control the activities of third parties, we do not accept responsibility for any use of your information by such third parties, and we do not guarantee that they will adhere to the same privacy and security practices as Health Connect. To determine how your information will be treated when accessing or using the Linked Sites, you must read the privacy policy, if any, that applies to each of the Linked Sites.

Health Service Providers are responsible for the privacy of patient Personal Health Information collected and held by the Health Service Provider and/or Health Connect as an affiliate of them, in the course of providing a Health Service to the patient. Health Connect as an affiliate of the Health Service Providers is required to abide by the privacy policies of Health Service Providers in accordance with Health Connect’s agreements with them and applicable legislation. This Privacy Policy applies to individuals receiving a Health Service to the extent that it supports the Health Service Provider’s policies and clarifies our approach to safeguards and compliance in relation to this obligation. At all times, the Health Service Provider’s policies and related agreements and applicable legislation they are subject to take precedence to this Privacy Policy.

CONSENT– PERSONAL INFORMATION

BY SUBMITTING PERSONAL INFORMATION TO HEALTH CONNECT AS A CLIENT, YOU AGREE THAT HEALTH CONNECT MAY COLLECT YOUR PERSONAL INFORMATION AND YOU CONSENT TO THE USE, DISCLOSURE AND TRANSFER OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY AND AS PERMITTED OR REQUIRED BY LAW.  

Subject to legal and contractual requirements, you may refuse or withdraw your consent to the collection, use, disclosure, and transfer of your Personal Information for certain of the purposes identified herein at any time by contacting Health Connect through the contact details provided below. If you refuse or withdraw your consent, you acknowledge that Health Connect may not be able to provide you or continue to provide you with certain Services which may be of value to you.

CONSENT– PERSONAL HEALTH INFORMATION

BY PROVIDING PERSONAL HEALTH INFORMATION TO YOUR HEALTH SERVICE PROVIDER DIRECTLY AND/OR TO HEALTH CONNECT HEALTH AS THEIR AFFILIATE, AS PART OF RECEIVING A HEALTH SERVICE FROM THEM, YOU AGREE THAT THE HEALTH SERVICE PROVIDER AND THEIR AFFILIATE(S) MAY COLLECT YOUR PERSONAL HEALTH INFORMATION AND YOU CONSENT TO THE USE, DISCLOSURE, AND TRANSFER OF YOUR PERSONAL HEALTH INFORMATION TO FACILITATE RECEIVING THIS SERVICE, IN ACCORDANCE WITH THE HEALTH SERVICE PROVIDER’S PRIVACY POLICIES AND AS PERMITTED OR REQUIRED BY LAW.

Subject to statutory and contractual requirements, you may refuse or withdraw your consent to the collection, use, disclosure, and transfer of your Personal Health Information for certain of the purposes identified by your Health Service Provider and/or their affiliates at any time by contacting your Health Service Provider through the contact details provided by them. Alternatively, you may submit such requests through Health Connect as an affiliate of your Health Service Provider, and we will take all reasonable steps to forward your request to the Health Service Provider involved. If you refuse or withdraw your consent, you acknowledge that your Health Service Provider may not be able to provide you or continue to provide you with certain Health Services which may be of value to you.

COLLECTION OF INFORMATION

We may collect information as follows:    

(a) Information You Provide to Us. When you engage in receiving a Service of any type from Health Connect as a client or from your Health Service Provider as a patient that requires the submission of information; we may collect such minimum information as is necessary to deliver the requested Service. This information is necessary for actions such as verifying your identity, fulfilling your orders, and contacting you concerning the Service you requested.  Whether you are providing information as a client of Health Connect or as a patient of a Health Service Provider that we are an affiliate of, we store your collected information securely both for your security and for use to verify your identity and provide you with the Services you requested and therefore provided said information to obtain.

(b) Information Provided to Us by Your Patient Representative or by You for a Person for whom You will be a Patient Representative. If an individual wishes to have you act as a Patient Representative for the purposes of managing the individual’s Services, or you ask another to act as Your Patient Representative, we may collect information about you and the other person as detailed in the previous paragraph to facilitate delivery of that Service. This includes situations such as where a parent or guardian is acting as a Patient Representative for a minor or incapacitated person. As per the previous paragraph, the minimum information necessary to deliver the requested Service will be collected.

(c)When You or Your Patient Representative make purchases for Services from your Health Service Provider through the Health Connect platform, you may make use of the Health Service Provider’s payment system or may instead be presented with the option to engage the Health Connect platform’s partnered payment processor. Where the Health Service Provider’s system is used to make payment and/or where our payment processor is used, the payment processor involved will collect and be responsible for any credit card information you provide for making payment. Health Connect does not in either case collect or store your credit card information.   Other Personal Information provided for making payment, such as your insurance information, policy numbers, and group plan information you may provide as part of obtaining Services, that pass through and/or are stored in the Health Connect system, will be handled in a secure manner at all times in accordance with our privacy policies, applicable legislation, and contractual obligations with your Health Service Provider.    

(d) When You or Your Patient Representative receive a Health Service such as a vaccination from a Health Service Provider through our Site and/or Services, you or your Patient Representative will provide your Health Service Provider with your Personal Health Information (through Health Connect as an affiliate of the Health Service Provider). For prescriptions this information can include the medication requested, amounts, and information regarding your physician or other prescribing professional.  Your chosen Health Service Provider will use the Personal Health Information provided to fill your prescription as required.  This information will be stored, processed and shared with the applicable provincial prescription drug information system by your chosen Health Service Provider using their information system as required by the Law in the applicable jurisdiction.  Your chosen Health Service Provider may also contact your physician or the prescribing professional if additional information regarding your prescription is required.

(e) Public Forums. If you choose to make use of any public forum, blog, comment section or chat function managed by Health Connect to submit or post a message, query, or comment that involves Personal Information, we cannot guarantee and are not responsible for that information’s privacy or security. We place disclaimers in all Public Forums managed by Health Connect warning that these forums are public and therefore are not an appropriate place to post Personal Information or expect responses the same for any reason.    

(f) In-Media Communications. If you choose to send a message to us through our message, chat, post or other function, we may collect any information you may disclose through such means in order to facilitate the communication.   

(g) Push Notifications. If you choose to enable push notifications, we may use your information such as a device ID or email address in order to send push notifications to your device as per your direction.    

(h) Your Location. As a client, we may collect precise geolocation information as part of delivering requested Services to you such as locations of Health Service Providers close to you, provided that prior to doing so initially, you are either informed by us of such a practice or be prompted by us to allow or disallow such a feature. In the event you proceed with using any of the Site, Content and/or Services or choose to allow such a feature, you will have the ability to disallow it at any time thereafter by taking the appropriate steps in the applicable settings tab. We do not keep or store precise geolocation information. Precise geolocation is only collected and used at the time Health Connect delivers the requested Services.   

(i) Information You Submit to Us. If you choose to submit Personal Information to us for any other reason in any other form, we will collect such information and use it for the purposes for which you submitted it.    

(j) Visiting our Site. We will not collect any Personal Information from you simply by virtue of your visiting our Site; we only collect Personal Information if you or your Patient Representative choose to submit it to us. We do, however, collect Non-Personal Information such as Usage Data, for purposes of connection management and platform improvement whenever you use our Site and/or Services. Such Non-Personal Information is not combined with Personal Information unless you choose to submit Personal Information to us.

(k) Where Permitted by Law. We may also collect information, including Personal Information as otherwise permitted by law.    

(l) Do Not Track (DNT). Do Not Track (DNT) is a privacy preference that you can set in their web browser allowing you to opt out of tracking by websites and online services.  At the present time, the World Wide Web Consortium (W3C) has not yet established universal standards for recognizable DNT signals, and, therefore, Health Connect does not recognize DNT.    

(m) "Cookies". In connection with the foregoing collection of information, we may also use “cookies” or similar technologies (small amounts of data that are stored on your computer's hard drive, mobile device or media system platform when you use or access our Site and/or Services that identify your computer, mobile device or media system platform and may store information about you such as behavioural data. Should you choose to submit Personal Information to us, we may link cookie information to such Personal Information. If you do not wish to accept cookies, you have the option of blocking or disabling cookies. However, please be aware that some of our Site, Content and/or Services will not function properly if you do so and you may lose access to Services you purchased.    

(n) Third Party Advertising Partners. Health Connect is not engaged with any third-party advertising partners at this time and does not in any way collect Personal Information for such activities. Where the Health Connect platform in turn links you to a Health Service Provider platform as part of your receiving a requested Service from the Health Service Provider, there may be third-party advertising partners involved with the Health Service Provider and their platform.  The Health Service Provider at all times remains responsible for any information that may be shared with these third-parties through the Health Service Provider’s own platform, and you or your Patient Representative should contact the Health Service Provider directly for any related questions or concerns.

PUBLIC INFORMATION INCLUDING USER GENERATED CONTENT

You may choose to disclose information about yourself in the course of contributing User Generated Content to us or through your use of our Site and/or Services including but not limited to our online forums,"profiles" for public view or in similar forums on our Site. Information that you disclose in any of these forums is unencrypted, public information, may be accessed or recorded by Health Connect, and there is no expectation of privacy or confidentiality therein.

You should be aware that any Personal Information you submit in the course of these public activities can be read, collected, or used by other users of these forums, and could be used to send you unsolicited messages. We are not responsible for the Personal Information you choose to make public in any of these forums.

Note also that in the ordinary course of Internet activity, depending on your personal Internet web browser settings, certain information may be sent by your browser to third parties such as advertising networks and analytics companies. Health Connect has no control over the information sent from your browser to those networks or other third parties and recommends that you ensure that your browser settings prevent the disclosure of information you would not like to share.

If you post a video, image or photo on our Site for public view you should be aware that these may be viewed, collected, copied and/or used by other users without your knowledge or consent. We are not responsible for the videos, images or photos that you choose to submit to our Site. Please see our Terms of Use at Terms of Use on this point and for other guidelines about posting content on any of our Site.

USE OF YOUR INFORMATION

As a patient of a Health Service Provider that we are an affiliate to, Health Connect will only use your Personal Health Information in the manner and for the purposes authorized and directed by the Health Service Provider as part of delivering the requested Health Service to you, in accordance with the Health Service Provider’s policies, our agreements with them, and applicable legislation they are subject to.  At no time will your Personal Health Information be used for any other purpose without such direction and authorization. As a client of Health Connect, we may use your Personal Information for administrative, analytical, optimisation, security and other purposes, including, without limitation, the following purposes:    

(a) to develop, enhance, market, sell or otherwise provide information, products, services and functionality that you have requested, including the Services;    

(b) to improve our Site and/or Services, and to inform the creation of future Services;    

(c) to send you information related to our Site /or Services, including confirmations, technical notices, updates, security alerts, information related to product safety or recall issues and support and administrative messages;   

(d) to process transactions for the Services;    

(e) to manage your account with Health Connect;    

(f) to attempt to contact you regarding product safety or recall issues;   

(g) to track your access to and use of our Site and/or Services to help us learn more about your Site and/or Services access and usage, Content consumption and understand your preferences and tendencies so that we can personalize your experience, provide you with offers and notifications that are tailored to you, and otherwise enhance your experience when viewing Content and using our Site and/or Services;    

(h) to personalize your Health Connect Content and offers;    

(i) to respond to customer service inquiries;    

(j) to troubleshoot problems with the Site and/or Services;    

(k) to enforce our Terms of Use;    

(l) to protect against unlawful activities or other misuse of the Site, Content and/or and Services or for other security reasons;    

(m) to provide you with notices on behalf of your Health Service Provider on appropriate Services available to you that suits your needs and profile and is age/gender-appropriate and targeted to your region;   

(n) to compile statistics;    

(o) to send you push notifications (if you choose to allow that function); and   

(p) for any other purpose to which you consent or that is otherwise permitted or required by law.

By providing Personal Information through the access to, or use of, our Site, Content and any of our Services, you acknowledge and agree that we may use the Personal Information for the purposes set out in this Privacy Policy and by accessing, using, or installing any of our Site, Content and/or Services or submitting information to us you also agree that we may use the Non-Personal Information for the purposes set out in this Privacy Policy.

NOTIFICATIONS

If you have provided your email/cell phone number and provided consent to receive notifications under Canadian Anti-Spam Law (CASL), we may occasionally send you as a client important information regarding your Service. We may also send you notices on behalf of your Health Service Provider as part of Health Services you have requested from them. If you no longer wish to receive notifications, you may cancel your Service, or contact the Health Service Provider in the case of notifications provided to you on their behalf.

DISCLOSURE OF INFORMATION

As a patient of a Health Service Provider that Health Connect is an affiliate of, Health Connect will only disclose your Personal Health Information in the manner and for the purposes authorized and directed by the Health Service Provider as part of delivering the requested Health Service to you, in accordance with the Health Service Provider’s policies, our agreements with them, and applicable legislation they are subject to.  At no time will your Personal Health Information be disclosed for any other purpose without such direction and authorization.

As a client, Health Connect will not transfer your Personal Information to third parties, except for our subsidiaries, affiliates and business partners that we engage to provide services on our behalf, such as (but not limited to) web hosting services, credit card payment processing, provincial/private health plan coverage, pharmacy software providers, and order processing and delivery, provided that such third parties have agreed to comply with legally required privacy standards and they agree to use the information only for the purposes disclosed at the time of collection or for a use consistent with that purpose. Health Connect will only provide those third parties with the Personal Information that they need to deliver the Services requested by you to Health Connect or on Health Connect’s behalf.

We may also disclose your Personal Information to a third-party vendor whose products or services you have requested in order to deliver those products and services to you. Such third parties may keep and use your Personal Information whether or not you purchase their products or services. Your Personal Information will be subject to their privacy policies, so you should contact them directly for information or to voice your concerns in respect of their policies.

Notwithstanding the foregoing, we reserve the right to disclose information, including Personal Information, if we reasonably believe that we are required to do so by law or legal process or if we are otherwise requested by any law enforcement officer or agency acting under colour of law. In addition, we reserve the right to disclose Personal Information and Non-Personal Information in order to: (a) enforce the Terms of Use Terms of Use; (b) investigate and/or take action against unlawful activity, suspected abuse or unauthorized use of the Site and Services; (c) protect and defend the rights or property of Health Connect; or (d) act in urgent circumstances, including medical emergencies, to protect your safety or security of the public. Subject to applicable privacy laws, we may also disclose your information (including Personal Information) in connection with a corporate reorganization, a merger or amalgamation with another entity, or a sale of all or a substantial portion of our assets provided that the information disclosed continues to be used for the purposes permitted by this Privacy Policy by the entity acquiring the information and if we do so, we will notify you that such a transaction has taken place and that your Personal Information has been disclosed.

We may also share aggregate or anonymized information, including de-identified Personal Information, about you with service providers, business partners, and other third parties, to the extent permitted by applicable law.

We may share your Usage Data and other Non-Personal Information including, without limitation, device IDs, advertising IDs or other persistent identifiers and non-precise geolocation information with third parties who assist us with our operations such as administration, analytics, planning and optimization, and with our business partners in order to, among other things, allow them to provide better service to Health Connect or on Health Connect’s behalf.

PROTECTION OF YOUR INFORMATION

As required by applicable privacy laws, Health Connect has implemented reasonable physical and technical measures to protect the information we collect or are provided with from unauthorized access and against loss, misuse or alteration by third parties. Further, while we attempt to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers" from illegally obtaining access to this information. We do not warrant or represent that your information will be protected against, loss, misuse, or alteration by third parties. No method of transmission over the Internet, or method of electronic storage, is 100% secure.

TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY GUARANTEE OF SECURITY IN CONNECTION WITH YOUR PERSONAL INFORMATION.

For further information on how we safeguard information we collect or are provided with, contact us via email at info@phhealthconnect.com.

RETENTION OF YOUR INFORMATION

We retain Personal Health Information you provide only as long as your Health Service Provider directs us to, in accordance with the Health Service Provider’s policies, our agreements with them, and applicable legislation they are subject to.

As a client, the data and information that we collect from you (including Personal Information) will be stored and maintained by Health Connect or our third-party service providers until you delete it or instruct your Health Service Provider to delete it.

Currently, Health Connect or our third-party service providers retain and store information collected by, or provided to, us in the cloud and on secure servers in Canada. Some of our third-party service providers may retain and store limited contact information (including Personal Information) outside of Canada for services such as appointment reminder communications that you have requested or accessed. Under the laws of those other jurisdictions, in certain circumstances courts, law enforcement agencies, regulatory agencies or security authorities in those other provinces or foreign jurisdictions may be entitled to access this information.  At all times you will be made aware of when and what information they are sharing outside of Canada and have the option to not share this information and engage these services.

ACCOUNT SECURITY

If you access our Site and/or use any of our Services, you are responsible for protecting the confidentiality of your account password and account information, and for restricting access to your computer, device or media system platform, and you agree to accept responsibility for all activities that occur under your account.  Please notify us immediately if you detect suspected breach or misuse of your account.

ACCESS AND ACCURACY 

Health Service Providers remain responsible at all times for your Personal Health Information collected, used, stored, and retained in the course of providing you Health Services.  Any requests to access, amend/correct, or withhold access to or disclosure of your Personal Health Information are to be directed to your chosen Health Service Provider.  Any such requests received by Health Connect will be directed to the Health Service Provider involved and you will be notified of this action and how to contact your Health Service Provider for this matter.

As a client, for any Health Connect platform specific Personal Information (if any) that is not specifically involved with Services from a Health Service Provider (and thus is not the responsibility of the Health Service Provider), Health Connect will use commercially reasonable efforts to provide you access to your Personal Information (to the extent we are in possession of any) if you submit your request for access via info@phhealthconnect.com.  Subject to applicable law, Health Connect reserves the right to deny access to your Personal Information on any of the following grounds:    

(a)when denial of access is required by law;    

(b)when granting you access is reasonably likely to negatively impact other people's privacy;    

(c)when granting access is, in our judgment and acting reasonably, cost prohibitive; or    

(d)when we have reason to believe that such requests are frivolous or made in bad faith. 

You are responsible for ensuring that all information created through your access to and use of the Site and/or Services is accurate, reliable and complete and you acknowledge and accept that the use of such information is at your own risk. We ask that you keep the Personal Information that you provide to us current and correct. You represent and warrant that all Personal Information you provide us is true and correct and relates to you and not to any other person.

If you believe that the Personal Information maintained by Health Connect (not the Health Service Provider) about you, if any, is inaccurate or incomplete, you may notify us by describing in detail any inaccuracies or omissions via email at info@healthconnect.com.  Following receipt of a properly submitted notice, we will, within a reasonable time period and acting in our sole discretion, use commercially reasonable efforts to either: (a) amend or correct your Personal Information to reflect corrected or additional information provided by you, or (b) in connection with your Personal Information, make note of any claimed inaccuracies or omissions reported in the notice submitted by you.

OUR POLICY REGARDING CHILDREN

Health Connect recognizes the privacy interest of children, and our Site, Content and/or Services are not intended for children under the age of majority in your jurisdiction and we do not target our Site, Content and/or Services to children under the age of majority. Health Connect does not knowingly collect or use any Personal Information from children under the age of majority unless provided by a parent or guardian using the Site and/or Services on behalf of such minor. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, please contact us at info@healthconnect.com.  We will delete such information from our files within a reasonable time.

GOVERNING LAW

Those who choose to use or access the Site and/or Services from outside Canada do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Notwithstanding the foregoing, and recognizing the global nature of the Internet, each individual shall comply with all local rules regarding online conduct and submission of acceptable materials. This Privacy Policy is governed and interpreted pursuant to the laws of the laws of the applicable province where your dispensing pharmacy is located and the federal laws of Canada applicable therein, without regard to principles of conflicts of law that would impose the law of another jurisdiction, and you accept and submit to the exclusive jurisdiction of the courts located within the province where your applicable dispensing pharmacy is located.

QUESTIONS OR COMMENTS

We welcome questions or comments about this Privacy Policy. Please direct any questions or comments to the individual below who oversees Health Connect‘s compliance with privacy requirements:

Health Connect team 
info@phhealthconnect.com
`;

  return (
    <div className="contentC">
      <Background />
      <div className="contentMC">
        <h1>Privacy Policy</h1>
        <pre className="policy-text" style={{ color: "#000" }}>
          {policyText}
        </pre>
      </div>
    </div>
  );
}
