import React from "react";
import Lottie from "react-lottie";

export default function Splash() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../loading.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="splash"> 
      <Lottie options={defaultOptions} height={160} width={160} />
    </div>
  );
}
