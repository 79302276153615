import React, { useEffect, useState } from "react";
import Background from "../componants/Background";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import moment from "moment";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

export default function Rejected() {
  const [requests, setRequests] = useState([]);
  const [rjCount, setRjCount] = useState(null);

  const auth = getAuth();
  const db = getFirestore();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../homeDoc.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: require("../empty.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function getInfo() {
      const querySnapshot = await getDocs(
        query(
          collection(db, "pharmacies", auth.currentUser.email, "rejected"),
          orderBy("timeStamp", "desc")
        )
      );
      setRequests(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
      const getrjCount = collection(db, "pharmacies", auth.currentUser.email, "rejected");
      const rjCount = await getCountFromServer(getrjCount);
      setRjCount(rjCount.data().count);
    }

    getInfo();
  }, [requests]);

  const reAccept = async (request) => {
    const newDocRef = await addDoc(
      collection(db, "pharmacies", auth.currentUser.email, "requests"),
      {
        pName: request.data.pName,
        pEmail: request.data.pEmail,
        pPhone: request.data.pPhone,
        pPhoto: request.data.pPhoto,
        pAddress: request.data.pAddress,
        pCity: request.data.pCity,
        pDateOfBirth: request.data.pDateOfBirth,
        pPostalCode: request.data.pPostalCode,
        timeStamp: request.data.timeStamp,
        form: request.data.form,
        attachedPic: request.data.attachedPic ? request.data.attachedPic : null,
        q1: request.data.q1,
        a1: request.data.a1,
        q2: request.data.q2,
        a2: request.data.a2,
        q3: request.data.q3,
        a3: request.data.a3,
        q4: request.data.q4,
        a4: request.data.a4,
        q5: request.data.q5 ? request.data.q5 : null,
        a5: request.data.a5 ? request.data.a5 : null,
        q6: request.data.q6 ? request.data.q6 : null,
        a6: request.data.a6 ? request.data.a6 : null,
        q7: request.data.q7 ? request.data.q7 : null,
        a7: request.data.a7 ? request.data.a7 : null,
        q8: request.data.q8 ? request.data.q8 : null,
        a8: request.data.a8 ? request.data.a8 : null,
        q9: request.data.q9 ? request.data.q9 : null,
        a9: request.data.a9 ? request.data.a9 : null,
        q10: request.data.q10 ? request.data.q10 : null,
        a10: request.data.a10 ? request.data.a10 : null,
        q11: request.data.q11 ? request.data.q11 : null,
        a11: request.data.a11 ? request.data.a11 : null,
        q12: request.data.q12 ? request.data.q12 : null,
        a12: request.data.a12 ? request.data.a12 : null,
        q13: request.data.q13 ? request.data.q13 : null,
        a13: request.data.a13 ? request.data.a13 : null,
        q14: request.data.q14 ? request.data.q14 : null,
        a14: request.data.a14 ? request.data.a14 : null,
        q15: request.data.q15 ? request.data.q15 : null,
        a15: request.data.a15 ? request.data.a15 : null,
        q16: request.data.q16 ? request.data.q16 : null,
        a16: request.data.a16 ? request.data.a16 : null,
        q17: request.data.q17 ? request.data.q17 : null,
        a17: request.data.a17 ? request.data.a17 : null,
        q18: request.data.q18 ? request.data.q18 : null,
        a18: request.data.a18 ? request.data.a18 : null,
        q19: request.data.q19 ? request.data.q19 : null,
        a19: request.data.a19 ? request.data.a19 : null,
      }
    ).then(async () => {
      await deleteDoc(doc(db, "pharmacies", auth.currentUser.email, "rejected", request.id));
    });
  };

  return (
    <div className="contentC">
      <Background />
      <div style={{ display: "flex", flex: 1, marginTop: 120 }}>
        <div
          style={{
            backgroundColor: "#2872fa",
            borderRadius: 10,
            padding: 15,
            paddingLeft: 30,
            paddingRight: 30,
            transition: "1s",
            marginBottom: 23,
          }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
            <img src={require("../req.png")} style={{ width: 38, height: 38, marginRight: 10 }} />
            <h1 style={{ color: "#fff" }}>Rejected Requests</h1>
          </div>
          {requests.map((request) => (
            <div key={request.id} className="request">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={request.data.pPhoto ? request.data.pPhoto : require("../avatar.png")}
                  className="pProfilePic"
                  style={{
                    width: 55,
                    height: 55,
                    borderRadius: 50,
                    resize: "cover",
                    marginRight: 10,
                  }}
                />
                <div
                  style={{
                    height: 30,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                  <h3 style={{ color: "#000" }}>{request.data.pName}</h3>
                  <p style={{ fontSize: 12, margin: 0, color: "gray" }}>
                    {moment(request.data.timeStamp?.toDate()).locale("en").fromNow()}
                  </p>
                </div>
              </div>

              <p className="pDetails">
                <strong>Case:</strong> {request.data.form}
              </p>
              <p className="pDetails">
                <strong>City:</strong> {request.data.pCity}
              </p>
              <p className="pDetails">
                <strong>Date Of Birth:</strong>{" "}
                {request.data.pDateOfBirth.toDate().toDateString().substring(4)}
              </p>
              <div
                style={{
                  width: "50vw",
                  height: 45,
                  display: "flex",
                  borderRadius: 25,
                  overflow: "hidden",
                  marginTop: 20,
                  marginBottom: -10,
                  alignSelf: "center",
                }}>
                <div
                  onClick={() => reAccept(request)}
                  className="macBTN"
                  style={{
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}>
                  <p>
                    <strong>Re-accept Request</strong>
                  </p>
                </div>
                <Link
                  to={{
                    pathname: "/-Request",
                    state: request,
                  }}
                  className="vmdBTN"
                  style={{
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}>
                  <p style={{ color: "#fff" }}>
                    <strong>View More Details</strong>
                  </p>
                </Link>
              </div>
            </div>
          ))}
          {rjCount == 0 && (
            <div style={{ width: "45vw" }}>
              <h2 style={{ color: "#fff", marginTop: 70, textAlign: "center" }}>
                You currently don't have any Rejected requests
              </h2>
              <Lottie options={defaultOptions2} height={350} width={350} style={{ opacity: 0.8 }} />
            </div>
          )}
        </div>
        <div
          style={{
            flex: 0.3,
            backgroundColor: "#2872fa",
            borderRadius: 10,
            padding: 15,
            marginLeft: 15,
            minWidth: "25vw",
            height: "80vh",
            position: "sticky",
            top: 100,
          }}>
          <img src={require("../chart.png")} />
          <h1 style={{ color: "#fff", marginBottom: 20 }}>Tasks Overview</h1>
          <p>
            <strong>Rejected: </strong>
            {rjCount + 0}
          </p>
          <Lottie options={defaultOptions} height={250} width={200} style={{ marginTop: 20 }} />
          <h2 style={{ color: "#fff", textAlign: "center", marginTop: -15 }}>
            Keep up the good work
          </h2>
        </div>
      </div>
    </div>
  );
}
